import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import ImageUpload from "./../ImageUpload";
import {Context} from "./../../context";
import { useState,useEffect,useContext } from "react";
import axios from 'axios';
const Gallery = () => {

    const[itemData,setItemData] = useState([])   
    const[update,setUpdate] = useState(true)   
    const contextValue=useContext(Context)
    async function handleDelete(imageName) 
{
      try {
          await axios.delete(`/api/files/image_delete/${imageName}`);
          setItemData(itemData.filter(x=>x!==imageName))
          contextValue.setTypeMsg("info")
          contextValue.setMsg(`Изображение ${imageName} успешно удалено.`)
          contextValue.setOpenAlert(true)
          // alert('Изображение успешно удалено');
      } catch (error) {
          console.error('Ошибка при удалении изображения:', error);
          alert('Произошла ошибка при удалении изображения');
      }
  }
  const [columns, setColumns] = useState(3); // Начальное значение колонок
  function DownloadImage()
    {      
        if(update)      
       { axios.get(`/api/files/getImages`)
        .then(res=>{setItemData(res.data)})
        .catch(err=>console.log(err)) 
        setUpdate(false)}
    } 
    useEffect(()=>{DownloadImage()},[update])
  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth < 600) {
        setColumns(3); // xs
      } else if (window.innerWidth < 960) {
        setColumns(5); // md
      } else {
        setColumns(7); // lg
      }
    };

    updateColumns(); // Устанавливаем начальное количество колонок

    window.addEventListener('resize', updateColumns); // Обновляем колонки при изменении размера окна

    return () => {
      window.removeEventListener('resize', updateColumns); // Удаляем слушатель при размонтировании
    };
  }, []);
  
    return (<>
        <ImageUpload setUpdate={setUpdate}/>
    <Box sx={{ display: 'flex', height: '100vh', position: 'relative' }}>
      {/* Левая часть с фоном и изображениями */}
      <Box
        sx={{
          flex: 1,
          backgroundImage: 'url(/path/to/your/background/image.jpg)', // Укажите путь к изображению фона
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        <ImageList variant="masonry" cols={columns} gap={2} sx={{ height: '100%' }}>
          {itemData.map((item) => (
            <ImageListItem key={item}>
              <img
                srcSet={`./uploads/images/${item}?w=248&h=496&fit=crop&auto=format&dpr=2 2x`}
                src={`./uploads/images/${item}?w=248&h=496&fit=crop&auto=format`}
                loading="lazy"
              />
              {contextValue.super_user === 1 && (
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      onClick={() => handleDelete(item)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                  actionPosition="right"
                />
              )}
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      </Box>
      </>
    )
}
 
export default Gallery;