
import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormDialog from "../../ModalWindow/Dialog/FormDialog";
import axios from 'axios';
import TrenerPage from "../../pages/TrenerPage";
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import {styled,Button} from '@mui/material';

// Стилизация первой таблицы
const FirstTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(1n)': {
    backgroundImage: `linear-gradient(to right, #E0FFFF, #E6E6FA)`,
    color: '#fff',
  },
}));


const StyledTableHead = styled(TableHead)`
  th {
    font-weight: bold;
    background-color: '#f0f0f0'; // Серый фон
    color: '#333';                // Темный текст
  }
`;


const UsersTable = (props) => {
    const contextValue=useContext(Context)
    const[editTrenerActive,seteditTrenerActive]=useState(false)
    const [users, setUsers] =useState([])


    useEffect(()=>
      {
        getUsers()
      },[]);

    
    
function getUsers()
{
  axios.get(`/api/users/getAllUsers`)
              .then(res=>{
                  console.log(res.data)
                  setUsers(res.data) 
})
.catch(err =>alert(err))
}
    function changeSuperUser(id,super_user)
    {
      axios.post(`/api/users/superUserChange/${id}`, {super_user:super_user})
      .then(res=>{
          if(res.data.Status==="Информация успешно изменена")
          {
            const updatedUsers=users.map(u=> {
              if (u.user_id === id) {
                // Обновляем имя роли
                return { ...u, super_user: super_user};
              }
              
              // Оставляем роль без изменений, если условие не выполнено
              return u;
            });
            setUsers(updatedUsers)
          }
          else console.log(res.data.Status);
      })
      .catch(err =>alert(err))
    }

    function changeIsActive(id,isActive)
    {
      axios.post(`/api/users/isActiveChange/${id}`, {isActive:isActive})
      .then(res=>{
          if(res.data.Status==="Информация успешно изменена")
          {
            const updatedUsers=users.map(u=> {
              if (u.user_id === id) {
                // Обновляем имя роли
                return { ...u, isActive: isActive};
              }
              
              // Оставляем роль без изменений, если условие не выполнено
              return u;
            });
            setUsers(updatedUsers)
          }
          else console.log(res.data.Status);
      })
      .catch(err =>alert(err))
    }

    function sendRestoreLink(email)
    {
      axios.post(`/api/users/password/restore_mail/${email}`)
      .then(res=>{
          if(res.data.Status==="На почту направлено письмо для восстановления пароля")
          {
            contextValue.setTypeMsg("success")
            contextValue.setMsg("На почту направлено письмо для восстановления пароля")
            contextValue.setOpenAlert(true)
          }
          else {
            contextValue.setTypeMsg("success")
            contextValue.setMsg(res.data.Error)
            contextValue.setOpenAlert(true)
          };
      })
      .catch(err =>alert(err))
    }

    return (  
          <>
          
          {editTrenerActive&&<FormDialog title={"Профиль сотрудника"} FormDialog width={'md'}  active={editTrenerActive} setActive={seteditTrenerActive} children={TrenerPage}/>}
          <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table" size='small' sx={{
            '& .MuiTableCell-sizeSmall': {
              padding: '3px',
            },
          }}>
            <StyledTableHead>
              <TableRow>
                <TableCell>№</TableCell> 
                <TableCell align="center"></TableCell>           
                <TableCell align="center">Название</TableCell>
                <TableCell sx={{width:'350px'}} align="center">Адрес</TableCell>                
                <TableCell align="center">email</TableCell>
                <TableCell align="center">Суперпользователь</TableCell>
                <TableCell align="center">Активная учетная запись</TableCell>
                <TableCell align="center"></TableCell>  
                <TableCell align="center"></TableCell>  
              </TableRow>
            </StyledTableHead>
          <TableBody>
            {
            users.filter(u=>u.trener_id===null)?.map((school,i)=>(
              <>
              <FirstTableRow
              key={school.school_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row">{i+1}</TableCell>
            <TableCell wrap align="center">
                <Avatar
                 key={contextValue.user_avatar_key} onerror={'./uploads/users/no_avatar.jpg'} src={contextValue.user_id===school.user_id?contextValue.user_avatar_crop:`./uploads/users/${school.user_id}/avatar_crop.png`} style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  filter: 'brightness(1.1) contrast(1.2)',
                  border: '2px solid #ccc',
                }}/>              
                </TableCell> 
            <TableCell wrap align="center">
              {`${school.school_name}`}              
              </TableCell> 
             <TableCell wrap align="center">
                {JSON.parse(school.user_address)?.data?.country || ''}
                {JSON.parse(school.user_address)?.data?.region_with_type ? `, ${JSON.parse(school.user_address)?.data.region_with_type} `: ''}
                {JSON.parse(school.user_address)?.data?.city ? `, ${JSON.parse(school.user_address)?.data.city} `: ''}
                {JSON.parse(school.user_address)?.data?.street_with_type ? `, ${JSON.parse(school.user_address)?.data.street_with_type} `: ''}
                {JSON.parse(school.user_address)?.data?.house_type ? `, ${JSON.parse(school.user_address)?.data?.house_type} `: ''}
                {JSON.parse(school.user_address)?.data?.house ? `, ${JSON.parse(school.user_address)?.data?.house}` : ''}
            </TableCell> 
             
             <TableCell wrap align="center">{`${school.user_email}`}</TableCell>              
             <TableCell wrap align="center"><Switch color="primary" checked={Boolean(school.super_user)} onChange={(event) =>changeSuperUser(school.user_id,event.target.checked?1:0)}/></TableCell> 
             <TableCell wrap align="center"><Switch color="primary" checked={Boolean(school.isActive)} onChange={(event) =>changeIsActive(school.user_id,event.target.checked?1:0)}/></TableCell> 
             <TableCell wrap align="center"><Button variant='outlined' onClick={() =>sendRestoreLink(school.user_email)}>Восстановить пароль</Button></TableCell>  
             <TableCell wrap align="center">
                  {/* <IconButton  aria-label="edit" onClick={()=>{
                    contextValue.setSelectSchool(school.school_id);
                    seteditTrenerActive(true)}}>
                      <EditOutlinedIcon />
                  </IconButton>                  */}
                  </TableCell>       
                  </FirstTableRow>
                  {(() => {
                    const treners=users.filter(x=>x.trener_id!==null && school.school_id===x.school_id)
                    return treners.length>0?<>
                   <TableRow>                  
                  <TableCell colSpan={8}>
                  <Box sx={{pl:3}} display='flex' justifyContent="center">
                  <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table" size='small' sx={{
                    '& .MuiTableCell-sizeSmall': {
                      padding: '3px',
                    },
                  }}>
                  <StyledTableHead>
              <TableRow>
                <TableCell>№</TableCell> 
                <TableCell align="center"></TableCell>           
                <TableCell align="center">ФИО</TableCell>               
                <TableCell align="center">email</TableCell>
                <TableCell align="center">Суперпользователь</TableCell>
                <TableCell align="center">Активная учетная запись</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </StyledTableHead>
          <TableBody>
                  {                    
                    treners?.map((trener,i)=>(
              <TableRow
              key={school.school_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
           
            <TableCell component="th" scope="row">{i+1}</TableCell>
            <TableCell wrap align="center">
                <Avatar
                 key={contextValue.user_avatar_key} onerror={'./uploads/users/no_avatar.jpg'} src={contextValue.user_id===trener.user_id?contextValue.user_avatar_crop:`./uploads/users/${trener.user_id}/avatar_crop.png`} style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  filter: 'brightness(1.1) contrast(1.2)',
                  border: '2px solid #ccc',
                }}/>              
                </TableCell> 
            <TableCell wrap align="center">
              {`${trener.trener_family} ${trener.trener_name} ${trener.trener_otchestvo}`}              
              </TableCell> 
             <TableCell wrap align="center">{`${trener.user_email}`}</TableCell> 
             <TableCell wrap align="center"><Switch color="primary" checked={Boolean(trener.super_user)} onChange={(event) =>changeSuperUser(trener.user_id,event.target.checked?1:0)}/></TableCell> 
             <TableCell wrap align="center"><Switch color="primary" checked={Boolean(trener.isActive)} onChange={(event) =>changeIsActive(trener.user_id,event.target.checked?1:0)}/></TableCell> 
             <TableCell wrap align="center"><Button variant='outlined' color="primary" onClick={() =>sendRestoreLink(trener.user_email)}>Восстановить пароль</Button></TableCell>              
             <TableCell wrap align="center">
                  <IconButton  aria-label="edit" onClick={()=>{
                    contextValue.setSelectTrener(trener.trener_id);
                    seteditTrenerActive(true)}}>
                      <EditOutlinedIcon />
                  </IconButton>
                  </TableCell> 
                  </TableRow>
                  ))}
            </TableBody>
            </Table>
            </TableContainer>
            </Box>  
            </TableCell>   
            </TableRow></> : null; 
          })()}
            </>))}  
            </TableBody>
        </Table>
    </TableContainer>  
</>
         
      );
}
 
export default UsersTable;