import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useContext,useEffect,useState } from "react";
import axios from 'axios';
import {Context} from "../context";
import "./../styles/main.css"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {createTheme } from '@mui/material/styles';


const theme = createTheme({
    shadows: ["none"]
  });
  
  theme.typography = {
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  };


const useStyles = makeStyles({
    input: {
      '& input[type=number]': {
          '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      }
    },
  });



const Result = (props) => {
    const classes = useStyles();
    const [order_member,setOrderMember] = useState([])
    const [results,setResults] = useState([])

    const contextValue=useContext(Context)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: "@page { size: auto; margin: 0mm; }",
        contentStyle: { transform: 'scale(0.8)', transformOrigin: 'top left' }
      });
    useEffect(()=>
        {
            props.orders!==null &&
                axios.get(`/api/orders/order_member/${props.orders}`)
                .then(res=>{
                    console.log(res.data)
                    setOrderMember(res.data[0]) 
                  })
                .catch(err=>console.log(err)) 
        },[props.orders]);
    useEffect(()=>
        {
            props.orders!==null &&
                axios.get(`/api/results/get_results/${props.orders}`)
                .then(res=>{
                        console.log(res.data)
                        setResults(res.data) 
                    })
                .catch(err=>console.log(err)) 
        },[props.orders]);

   
    const componentRef = useRef();
    return (
    <>
    <Box sx={{p:3 }} ref={componentRef}>
        <TableContainer sx={{ my:1}} >
        <Table style={{width:'100%', tableLayout: 'fixed'}} stickyHeader aria-label="sticky table" size='small'  sx={{
      '& .MuiTableCell-sizeSmall': {
        padding: '1px',
      },
    }}
  >
          <TableHead>
            <TableRow>
                <TableCell align="center" colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?10:8}  sx={{ border: 0 }}>
                <Typography fontSize={14}>Карточка участника</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell align="center" width={'120px'} sx={{ border: 1 }}>
                <Typography fontSize={10}>ВИД</Typography>
                </TableCell>}
                </TableRow>
                <TableRow>
                <TableCell align="center" colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?10:8}  sx={{ border: 0 }}>
                    <Typography fontSize={14}>{order_member.championat_name}</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell align="center" sx={{ border: 1 }}>
                    <Typography fontSize={10}>{order_member.sostav_type_name}</Typography>
                </TableCell>}
                </TableRow>               
            </TableHead>
            
            <TableBody>
            <TableRow >
                <TableCell align="center" colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?11:8} sx={{minHeight:30}}>

                </TableCell>
            </TableRow>
                <TableRow>
                <TableCell align="center" colSpan={2} width={'60px'} sx={{ border: 1 }}>
                <Typography fontSize={10}> 
                    Регион
                    Ведомство</Typography>
                </TableCell>
                <TableCell align="center" colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?5:3} sx={{ border: 1 }}>
                <Typography fontSize={10}>Фамилия, имя</Typography>
                </TableCell>
                <TableCell align="center" colSpan={1} width={'60px'} sx={{ border: 1 }}>
                <Typography fontSize={10}>Год рождения</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="Парно-групповое"&&
                <TableCell align="center" colSpan={2} width={'80px'} sx={{ border: 1 }}>
                    <Typography fontSize={10}>Разряд по которому выступают</Typography>
                </TableCell>}
                {contextValue.championat.championat_style_name==="КПН"&&
                <TableCell align="center" colSpan={1} width={'80px'} sx={{ border: 1 }}>
                <Typography fontSize={10}>Уровень подготовки</Typography>
                </TableCell>}
                <TableCell align="center" colSpan={1} sx={{ border: 1 }}>
                <Typography fontSize={10}>Тренеры</Typography>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="center" colSpan={2}  sx={{ border: 1 }}>
                <Typography fontSize={10}>{order_member.school_name}</Typography>
                </TableCell>
                <TableCell align="left" colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?5:3} sx={{ border: 1,whiteSpace: "pre-wrap"}}>
                <Typography fontSize={10}>{contextValue.championat.championat_style_name==="Парно-групповое"?order_member.FN:order_member.FN_KPN}</Typography>
                </TableCell>
                <TableCell align="center" colSpan={1} sx={{ border: 1,whiteSpace: "pre-wrap" }}>
                <Typography fontSize={10}>{contextValue.championat.championat_style_name==="Парно-групповое"?order_member.age:order_member.AGE_KPN}</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="Парно-групповое"&&
                <TableCell align="center" colSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={10}>{order_member.razryad_short_name}</Typography>
                </TableCell>
                }   
                {contextValue.championat.championat_style_name==="КПН"&&
                <TableCell align="center" colSpan={1} sx={{ border: 1 }}>
                <Typography fontSize={10}>{order_member.kpn}</Typography>
                </TableCell>
                }

                <TableCell align="center" colSpan={1} sx={{ border: 1,whiteSpace: "pre-wrap" }}>
                <Typography fontSize={10}>{order_member.treners}</Typography>
                </TableCell>
                </TableRow>
        {contextValue.championat.championat_style_name==="Парно-групповое"&&
        results?.map((o,i)=>(
            <>
            <TableRow >
                <TableCell align="center" colSpan={11} sx={{minHeight:30}}>

                </TableCell>
            </TableRow>
            <TableRow>
             <TableCell align="center" colSpan={11}  sx={{ border: 0 }}>
                <Typography fontSize={16}>{o.composition_type_name}</Typography>
            </TableCell>   
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={5}  sx={{ border: 1 }}>
                <Typography fontSize={10}>Техника исполнения</Typography>
              </TableCell>
              <TableCell align="center" colSpan={3}  sx={{ border: 1 }}>
              <Typography fontSize={10}>Сбавки СТ</Typography>
              </TableCell>
              <TableCell align="center" colSpan={2}  sx={{ border: 1 }}>
              <Typography fontSize={10}>Средняя оценка</Typography>
              </TableCell>
              <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
              <Typography fontSize={10}>Оценка исполнения</Typography>
              </TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="center"  sx={{ border: 1 }}>
              <Typography fontSize={10}>ПСБ</Typography>
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
              <Typography fontSize={10}>ТИ-1</Typography>
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
              <Typography fontSize={10}>ТИ-2</Typography>
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
              <Typography fontSize={10}>ТИ-3</Typography>
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
              <Typography fontSize={10}>ТИ-4</Typography>
              </TableCell>
              <TableCell align="center" width={'100px'} sx={{ border: 1 }}>
              <Typography fontSize={8}>Ошибки времени СУ</Typography>
              </TableCell>
              <TableCell align="center" width={'100px'}  sx={{ border: 1}}>
              <Typography fontSize={8}>Спец.треб.</Typography>
              </TableCell>
              <TableCell align="center" width={'100px'} sx={{ border: 1 }}>
              <Typography fontSize={8}>Запрещ.эл.</Typography>
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
              <Typography fontSize={10}>ТИ</Typography>
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
              <Typography fontSize={10}>ТИх2</Typography>
              </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).psbti}</Typography>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).ti1}</Typography>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).ti2}</Typography>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).ti3}</Typography>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).ti4}</Typography>
                </TableCell>  
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).ertime}</Typography>
                </TableCell>  
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).spec}</Typography>
                </TableCell>  
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).warn}</Typography>
                </TableCell>  
        

            <TableCell align='center' sx={{ border: 1 }}>
            <Typography fontSize={10}>{JSON.parse(o.score_json).avgti}</Typography>           
            </TableCell>

            <TableCell align='center' sx={{ border: 1 }}>
            <Typography fontSize={10}>{JSON.parse(o.score_json).avgtix2}</Typography> 
            </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).scoreisp}</Typography> 
                </TableCell>
                </TableRow>

                <TableRow>
                <TableCell colSpan={5} align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>Артистизм</Typography>
                </TableCell>
                <TableCell colSpan={5} align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>Сбавки ПСБ</Typography>
                </TableCell>
                <TableCell rowSpan={2} align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>Оценка артистизма</Typography>
                </TableCell>
                </TableRow>

                <TableRow>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>ПСБ</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-1</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-2</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-3</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-4</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>За рост</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>За костюм</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>Заступ</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>Сбавки ТЛ</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>Другие</Typography>
                </TableCell>                    
                </TableRow>

                <TableRow>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).psbart}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).art1}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).art2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).art3}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).art4}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).height}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).suit}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).zastup}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).sbavkitl}</Typography>
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).other}</Typography>
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{JSON.parse(o.score_json).scoreart}</Typography>
                </TableCell>
                </TableRow>

                <TableRow>
                <TableCell colSpan={2}  sx={{ border: 1 }}>
                <Typography fontSize={10}>Трудность</Typography>
                    </TableCell>
                    <TableCell align='center'  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{JSON.parse(o.score_json).difficulty}</Typography>
                </TableCell>
                    <TableCell colSpan={2}  sx={{ border: 1 }}>
                    <Typography fontSize={10}>Оценка трудности</Typography>
                    </TableCell>
                    <TableCell colSpan={2}  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{JSON.parse(o.score_json).scoredifficulty}</Typography>
                    </TableCell>
                    <TableCell colSpan={3}  sx={{ border: 1 }}> 
                    <Typography fontSize={10}>Общая оценка упражнения</Typography>
                    </TableCell>
                    <TableCell align='center'  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{JSON.parse(o.score_json).score}</Typography> 
                    </TableCell>
                </TableRow>
                <TableRow>
                </TableRow>
                </>
                ))}
                {contextValue.championat.championat_style_name==="КПН"&&
                 <>
                 <TableRow>
                 <TableCell align="center" colSpan={10}  sx={{ border: 0 }}>
                   
                 </TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={9}>Отжимания (кол-во раз)</Typography> 
                 </TableCell>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={10}>Прыжек в длину</Typography> 
                 </TableCell>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={8}>Подтягивания в висе на перекладине (кол-во раз)</Typography> 
                 </TableCell>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={10}>Пресс (30 сек)</Typography> 
                 </TableCell>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={9}>Наклон с тумбы (кол-во см)</Typography> 
                 </TableCell>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={8}>Гиперэкстензия (кол-во раз)</Typography> 
                 </TableCell>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={9}>Комбинация (на оценку)</Typography> 
                 </TableCell>
                 <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                 <Typography fontSize={10}>Среднее значение</Typography> 
                 </TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).kpn1}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).kpn2}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).kpn3}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).kpn4}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).kpn5}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).kpn6}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).kpn7}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={10}>{results.length>0&&JSON.parse(results[0]?.score_json).score}</Typography> 
                </TableCell>
                 </TableRow>
                </>
                }
                        <TableRow >
                        <TableCell align="center" colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?11:8} sx={{minHeight:30}}>

                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" colSpan={1}  sx={{ border: 0 }}>
                            
                        </TableCell>
                        <TableCell  colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?2:1} width={'60px'} sx={{ borderBottom: 1 }}>
                        <Typography fontSize={12}>Место</Typography>
                        </TableCell>
                        <TableCell align="center" colSpan={1}  sx={{ borderBottom: 1 }}>
                        <Typography fontSize={12}>{results[0]?.place}</Typography>
                        </TableCell>
                        <TableCell align="center" colSpan={1}  sx={{ border: 0 }}>
                            
                        </TableCell>
                        <TableCell  colSpan={2}  sx={{ borderBottom: 1 }}>
                        <Typography fontSize={12}>Сумма баллов</Typography>
                        </TableCell>
                        <TableCell align="center" colSpan={contextValue.championat.championat_style_name==="Парно-групповое"?4:1}  sx={{ borderBottom: 1 }}>
                        <Typography fontSize={12}>{results[0]?.full_score}</Typography>
                        </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                    </TableContainer>
                </Box>
                <Button onClick={handlePrint}>
                Печать
                </Button>
                </>
      );
}
 
export default Result;