import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';



const SostavsTableArchive = () => {
  const contextValue=useContext(Context)
    const [sostavs, setSostavs] =useState([])    
    
   
    useEffect(()=>
    {  
      axios.get(`/api/sostavs/archive/${contextValue.school_id}`)
      .then(res=>{
        console.log(res.data)
        setSostavs(res.data)})
      .catch(err=>console.log(err))  
    },[contextValue.setOpenAlert]);

   




    return (  
    <div className="max-w-full">
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>            
            <TableCell align="center">УЧАСТНИКИ</TableCell>
            <TableCell align="center">ВИД</TableCell>
            <TableCell align="center">ГОД РОЖДЕНИЯ</TableCell>
            <TableCell align="center">РАЗРЯД</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {sostavs?.map((sostav,i)=>(
               <TableRow
               key={sostav.sostav_id}
               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
             >
               <TableCell component="th" scope="row">
                  {i+1}
                  </TableCell>
                  <TableCell  sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">
                  {sostav.FIO.replaceAll("..",".")}             
                  </TableCell>   
                  <TableCell align="center">{sostav.sostav_type_name}                    
                  </TableCell>              
                  <TableCell  sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">            
                    {sostav.age}                    
                      </TableCell>
                  <TableCell  sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">           
                      {sostav.razryad}                        
                      </TableCell>            
                    </TableRow>))}          
                  </TableBody>
            </Table>
          </TableContainer>      
    
   
    </div>     
      );
}
 
export default SostavsTableArchive;