import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {IconButton, Button, CardActionArea, CardActions } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import logo from "./../../img/Logo.png"
import { makeStyles } from '@mui/styles';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {Context} from "../../context";
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { useContext} from "react";
const theme = createTheme({
  shadows: ["none"]
});

theme.typography = {
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Order = (props) => {
    const contextValue=useContext(Context)
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const useStyles = makeStyles({
        demowrap:{
            position:'relative',
            '&:before':{
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  filter: 'blur(3px) grayscale(50%)',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.2,
                  backgroundImage: `url(${logo})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50% 0',
                  backgroundSize: 'cover'
            }
        }
    })
    const classes = useStyles()

    function deleteOrder(id)
    {            
        axios.post(`/api/orders/order/delete/${id}`)
        .then(res=>{
            console.log(res)
            if(res.data.Status==="Заявка удалена")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Заявка удалена`)
              contextValue.setOpenAlert(true)
              setOpen(false);
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 
    
    function handleClickOpen() {
        setOpen(true);
      };
    
      const handleClose = () => {        
          setOpen(false);
      };

    return ( 
        <>
        <React.Fragment>            
         <Dialog
           open={open}
           TransitionComponent={Transition}
           keepMounted
           onClose={handleClose}
           aria-describedby="alert-dialog-slide-description"
         >
           <DialogTitle>{"Удалить заявку?"}</DialogTitle>
           <DialogContent>
             <DialogContentText id="alert-dialog-slide-description">
               Заявка на соревнование: "{props.championat_name}" будет удалена и более недоступна.
             </DialogContentText>
           </DialogContent>
           <DialogActions>
             <Button onClick={()=>deleteOrder(props.order_id)}>Да</Button>
             <Button onClick={handleClose}>Нет</Button>                
           </DialogActions>
         </Dialog>
       </React.Fragment>      
        <Card  className={classes.demowrap} 
        sx={{
          padding:0,             
          maxWidth: 320,
          minWidth:320,
          minHeight: 400,
          maxHeight: 400,
          display: "flex",
        flexDirection: "column"     
        }}>
            <CardActionArea 
             onClick={()=>{contextValue.setChampionatID(props.championat_id);
              localStorage.setItem('championatID', JSON.stringify(props.championat_id))
              localStorage.setItem('championat_type_name', JSON.stringify(props.championat_type_name))
              localStorage.setItem('championat_type_id', JSON.stringify(props.championat_type_id))
              localStorage.setItem('championat_style_name', JSON.stringify(props.championat_style_name))
              localStorage.setItem('championat_style_id', JSON.stringify(props.championat_style_id))
                localStorage.setItem('championat_name', JSON.stringify(props.championat_name))
                localStorage.setItem('championat_start_date', JSON.stringify(props.championat_start_date))
                localStorage.setItem('championat_end_date', JSON.stringify(props.championat_end_date))
                 localStorage.setItem('championat_adress', JSON.stringify(props.championat_adress))
                 localStorage.setItem('championat_final_date_order', JSON.stringify(props.championat_final_date_order))
                 localStorage.setItem('championat_phone', JSON.stringify(props.championat_phone))
              navigate("/order"); }}>
            <CardHeader  sx={{
                maxHeight:'130px',
                display: { xs: 'flex', md: 'flex' },
                overflow: 'auto'}}
                titleTypographyProps={props.championat_name.length>30?{variant:'body2'}:{variant:'h5'}}        
                title= {props.championat_name}                
                subheaderTypographyProps={{variant:'body2' }}   
                subheader={props.school_name}   
            />
             <CardContent disableSpacing sx={{ 
                padding:3,
                mt: "auto",
                maxHeight: 250,
                justifyContent:'center',
                overflow: 'auto'
                }}>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',
                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Ранг соревнования: </b>{props.championat_type_name}
                </Typography>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',
                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Тип соревнования: </b>{props.championat_style_name}
                </Typography>
                <Typography  sx={{
                        display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',
                        
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Место проведения: </b>{`${JSON.parse(props.championat_adress)?.data?.country!==null?JSON.parse(props.championat_adress)?.data.country:""}${JSON.parse(props.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(props.championat_adress)?.data.region_with_type}`:""}${JSON.parse(props.championat_adress)?.data?.city!==null?`, ${JSON.parse(props.championat_adress)?.data.city}`:""}${JSON.parse(props.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(props.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(props.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(props.championat_adress)?.data?.house_type}`:""}${JSON.parse(props.championat_adress)?.data?.house!==null?`,${JSON.parse(props.championat_adress)?.data?.house}`:""}`}
                </Typography>
                <Typography sx={{ my:1,
                        // display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Даты:</b> {props.championat_start_date} - {props.championat_end_date}
                </Typography>
                <Typography sx={{ my:1,
                        // display: { xs: 'flex', md: 'flex' },
                        textAlign:'left',
                        color: 'inherit',
                        textDecoration: 'none',
                        }} variant="body2" color="text.secondary">
                    <b>Срок подачи:</b> {props.championat_final_date_order}
                </Typography>
                
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing sx={{padding:0,margin:0, mt: "auto" }}>
                <Tooltip title="Редактировать заявку">
                <IconButton key="editOrder"
                      onClick={()=>{contextValue.setChampionatID(props.championat_id);
                      localStorage.setItem('championatID', JSON.stringify(props.championat_id))
                      localStorage.setItem('championat_type_name', JSON.stringify(props.championat_type_name))
                      localStorage.setItem('championat_type_id', JSON.stringify(props.championat_type_id))
                      localStorage.setItem('championat_style_name', JSON.stringify(props.championat_style_name))
                      localStorage.setItem('championat_style_id', JSON.stringify(props.championat_style_id))
                        localStorage.setItem('championat_name', JSON.stringify(props.championat_name))
                        localStorage.setItem('championat_start_date', JSON.stringify(props.championat_start_date))
                        localStorage.setItem('championat_end_date', JSON.stringify(props.championat_end_date))
                         localStorage.setItem('championat_adress', JSON.stringify(props.championat_adress))
                         localStorage.setItem('order_archive', JSON.stringify(props.archive))
                         localStorage.setItem('user_id', JSON.stringify(props.user_id))
                         
                 localStorage.setItem('championat_final_date_order', JSON.stringify(props.championat_final_date_order))
                 localStorage.setItem('championat_phone', JSON.stringify(props.championat_phone))
                         contextValue.setOrderMemberID(null) 
                      navigate("/order"); }}
                      fullWidth
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    {props.archive===0?<EditOutlinedIcon />:<FileOpenOutlinedIcon/>}
                </IconButton>
                </Tooltip>
                {props.archive===0&&<Tooltip title="Удалить заявку">
                <IconButton key="delOrder"
                      fullWidth
                      style={{display:'flex',maxHeight: '50px', minHeight: '30px',borderRadius:0}}>
                    <DeleteOutlineOutlinedIcon
                    onClick={handleClickOpen}/>
                </IconButton>
                </Tooltip>}
             </CardActions>
            </Card>
            </>
         );
    
}
 
export default Order;