import { useEffect, useState , useRef } from "react";
import {Context} from "../context";
import {useContext } from "react";
import axios from "axios";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button,Typography, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Popover } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import AvatarPreviewDialog from './AvatarPreviewDialog';
import {Avatar} from '@mui/material';
import {CssBaseline,List, ListItem, ListItemIcon, } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Back = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const TrenerPage = (props) => {
    const contextValue=useContext(Context)
    const [trener,setTrener]=useState({        
      trener_id:'',
      trener_family:'',
      trener_name:'',
      trener_otchestvo:'',
      trener_age:'',
      sud:'',
      trener:'',
      secret:'',
      user_email:'',
      user_id:''
      
    })

   
           

    

    const [selected_sud_categ, setSelectSudCateg] =useState(null) 
    const [selectedDate, setSelectedDate] =useState(null)
    const [sud_categ_types, setSudCategTypes] =useState([])
    const [sud_categs, setSudCategs] =useState([])
    const [sud_categ,setSudCateg]=useState({        
        trener_id:'',
        sud_categ_date_start:null,
        sud_categ_date_end:"",
        sud_categ_org:'',
        sud_categ_prikaz:'',
        sud_categ_type_id:""
      })

      function ClearSudCateg()
      {
        setSelectedDate(null);
        setSelectSudCateg(null)
        setSudCateg({...sud_categ,
            sud_categ_date_start:null,
            sud_categ_date_end:"",
            sud_categ_org:'',
            sud_categ_prikaz:'',
            sud_categ_type_id:""})
      }

      async function getSudCategs(id) {      
        let response = await axios.get(`/api/razryads/sud_categs/${id}`);
        let sud_categs = response.data;// Don't need await here
        setSudCategs(sud_categs);
        console.log("Судейские категории: ", {sud_categs})
    };
      async function getSudCategTypes() {      
        let response = await axios.get(`/api/razryads/sud_categ_types`);
        let sud_categ_types = response.data;// Don't need await here
        setSudCategTypes(sud_categ_types);
        console.log("типы судейских категорий ", {sud_categ_types})
    };


    function getTrener()
    {          
            axios.get(`/api/treners/trener/${contextValue.select_trener}`)
            .then(res=>{
            console.log(res.data[0])
            setTrener({...trener,
                trener_id:res.data[0].trener_id,
                trener_family:res.data[0].trener_family,
                trener_otchestvo:res.data[0].trener_otchestvo,                
                trener_age:res.data[0].trener_age,
                trener_name:res.data[0].trener_name,
                sud:res.data[0].sud,
                trener:res.data[0].trener,
                user_id:res.data[0].user_id,
                secret:res.data[0].secret,
                user_email:res.data[0].user_email
            })
            setSudCateg({...sud_categ,trener_id:res.data[0].trener_id})
            getSudCategs(res.data[0].trener_id)
            setImageUrl(`./uploads/users/${res.data[0].user_id}/stamp-crop.png?t=${new Date().getTime()}`)
            setSelectedItem('Личные данные');
        })
        .catch(err =>alert(err))
    }   
      
      useEffect(()=>
    {
        if(contextValue.select_trener)
        {getTrener();
        getSudCategTypes();}
        else setSelectedItem('Данные ведомства');
        
    },[contextValue.select_trener, contextValue.setOpenAlert])

    
    const [pass,setPass] = useState ({
        old_password:'',
        new_password:'',
        new_password_check:''
    })

    const [user_data,setUserData] = useState ({
        user_email:""
    })

    const [orgData,setOrgData] = useState ({
        school_name:"",
        school_adress:"",
        school_inn:""
    })

    useEffect(()=>{
        setUserData({...user_data,
        user_email:contextValue.user_email
        })
    },[contextValue.user_email])
    
        useEffect(()=>{
            setOrgData({...orgData,
                school_name:contextValue.school.school_name,
                school_adress:contextValue.school.school_adress,
                school_inn:contextValue.school.school_inn
            })
        },[contextValue.school])
        
    useEffect(()=>{
        if(sud_categ.sud_categ_date_start!==null && sud_categ.sud_categ_type_id!=="")
        {
            let date_end=new Date(sud_categ.sud_categ_date_start);
            date_end.setFullYear(date_end.getFullYear()+sud_categ_types[sud_categ_types.findIndex(x=>x.sud_categ_id===sud_categ.sud_categ_type_id)].sud_categ_time)
            setSudCateg({...sud_categ,sud_categ_date_end:date_end})
        }
    },[sud_categ.sud_categ_date_start,sud_categ.sud_categ_type_id])

    function handleSubmit(event)
    {
        can=true;
        contextValue.setMsg('');
        checkInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/treners/trener/edit/${trener.trener_id}`,trener)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Тренер изменен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Тренер изменен`)
              contextValue.setOpenAlert(true)
              props.setActive(false)       
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))  
        }
    }

    function AddSudCateg(event)
    {

        event.preventDefault();
        axios.post("/api/razryads/add_sud_categ/"+trener.trener_id,sud_categ)
        .then(res=>{
            if(res.data.Status==="Судейская категория добавлена")
            { 
                contextValue.setTypeMsg("success")
                contextValue.setMsg(`Судейская категория добавлена`)
                contextValue.setOpenAlert(true)  
            }
            else alert(res.data.Error);
        })
        .catch(err =>alert(err));    
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    function deleteSudCateg(id)
    {
        console.log(id)
        axios.post(`/api/razryads/delete_sud_categ/${id}/${trener.trener_id}`)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Судейская категория удалена")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Судейская категория  удалена`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))            
    }
    
    let can=true;
    function checkInput()
    {         
         if(trener.trener_family==='')
         {
            contextValue.setMsg("Введите фамилию")
            can=false
         }
         else if(trener.trener_name==='')
         {
            contextValue.setMsg("Введите имя")
            can=false
         }    
         else if(trener.trener_age==='')
         {
            contextValue.setMsg("Введите дату рождения")
            can=false
         }
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
    function checkEmailInput()
    {      
        if(user_data.user_email==='')
            {
                can=false;
                contextValue.setMsg("Не введен Email") 
            }
            else
            {
             if (!validateEmail(user_data.user_email))  {
                 can=false;
                 contextValue.setMsg("Email не корректен") 
               }
            }
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
    function checkPasswordInput()
    {      
            if(pass.old_password==='')
            {
                can=false;
                contextValue.setMsg("Не введен старый пароль") 
               
            }   
       
           else if(pass.new_password==='')
           {
               can=false;
               contextValue.setMsg("Не введен новый пароль") 
              
           }
           else if(pass.new_password!==pass.new_password_check)
           {
               can=false;
               contextValue.setMsg("Пароли не совпадают") 
           } 
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
    const [openList, setOpenList] =useState([])
    function handleSubmitPassword(event)
    {
        can=true;
        contextValue.setMsg('');
        checkPasswordInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/users/password_change/${contextValue.user_id}`,pass)
        .then(res=>{
            if(res.data.Status!==undefined)
            {if(res.data.Status==="Пароль успешно изменен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Пароль успешно изменен`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);}
            else 
            {
                contextValue.setTypeMsg("error")
                contextValue.setMsg(res.data.Error)
                contextValue.setOpenAlert(true)
            }
        })
        .catch(err =>alert(err))  
        }
    }
    function handleSubmitEmail(event)
    {
        can=true;
        contextValue.setMsg('');
        checkEmailInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/users/email_change/${trener.user_id}`,user_data)
        .then(res=>{
            if(res.data.Status!==undefined)
            {if(res.data.Status==="Email успешно изменен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Email успешно изменен`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);}
            else 
            {
                contextValue.setTypeMsg("error")
                contextValue.setMsg(res.data.Error)
                contextValue.setOpenAlert(true)
            }
        })
        .catch(err =>alert(err))  
        }
    }
    function checkSchoolDataInput()
    {
      
          if(orgData.school_adress==='')
            {
                can=false;
                contextValue.setMsg("Не введен адрес") 
               
            }
           
      
          if(orgData.school_name==='')
         {
             can=false;
             contextValue.setMsg("Не введено называние организации") 
            
         }
      
     
     if(!can)
     {
      contextValue.setTypeMsg("error")
      contextValue.setOpenAlert(true)
      }
 }
    function handleSubmitSchoolData(event)
    {
        can=true;
        contextValue.setMsg('');
        checkSchoolDataInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/users/school_data_change/${contextValue.school_id}`,orgData)
        .then(res=>{
            if(res.data.Status!==undefined)
            {if(res.data.Status==="Информация успешно изменена")
            {
              contextValue.setSchool(
                {
                    school_id:contextValue.school_id,
                    school_name:orgData.school_name,
                    school_inn:orgData.school_inn,
                    school_adress:orgData.school_adress
                }
                )  
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Информация успешно изменена`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);}
            else 
            {
                contextValue.setTypeMsg("error")
                contextValue.setMsg(res.data.Error)
                contextValue.setOpenAlert(true)
            }
        })
        .catch(err =>alert(err))  
        }
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const canvasRef = useRef(null);
   
   
    const [defaultInputValue, setDefaultInputValue] =useState("")
    useEffect(()=>{
        console.log(orgData.school_adress)
        if(orgData.school_adress!=="")
       { 
        let address = `${JSON.parse(orgData.school_adress)?.data?.country!==null?JSON.parse(orgData.school_adress)?.data.country:""}${JSON.parse(orgData.school_adress)?.data?.region_with_type!==null?`, ${JSON.parse(orgData.school_adress)?.data.region_with_type}`:""}${JSON.parse(orgData.school_adress).data?.city!==null?`, ${JSON.parse(orgData.school_adress).data.city}`:""}${JSON.parse(orgData.school_adress)?.data?.street_with_type!==null?`, ${JSON.parse(orgData.school_adress)?.data?.street_with_type}`:""}${JSON.parse(orgData.school_adress)?.data?.house_type!==null?`, ${JSON.parse(orgData.school_adress)?.data?.house_type}`:""}${JSON.parse(orgData.school_adress)?.data?.house!==null?`,${JSON.parse(orgData.school_adress)?.data?.house}`:""}`
        orgData.school_adress!==""&&setDefaultInputValue(address)
        }
    },[orgData.school_adress])
    const [options, setOptions] = useState([]);
    const handleInputChange = async (event, value) => {
        setDefaultInputValue(value)
        setOpenList(false)
        if (value) {
          let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + `0bcf160322fba45f1c4c583a62aafa04057c0629`
            },
            body: JSON.stringify({query: value})
        }
          try {
            const response = await fetch(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,options).then(x => x.json());
            if(response.suggestions.length>0) setOptions(response.suggestions.map(item=>item));
          } catch (error) {
            console.error(error);
          }
        } else {
          setOptions([]);
        }
        setOpenList(true)
      }
      const [preview, setPreview] = useState(null);
      const [open, setOpen] = useState(false);
    
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(event.target.files[0])
          setPreview(URL.createObjectURL(file));
          setOpen(true);
        }
      };
    
      const handleClose = () => {
        setOpen(false);
        const newImageUrl = `./uploads/users/${trener.user_id?trener.user_id:contextValue.user_id}/stamp-crop.png?t=${new Date().getTime()}`;
        setImageUrl(newImageUrl);
      };

      const menuItems = [
        contextValue.select_trener!==null&&{ text: 'Личные данные', icon: <AccountCircleIcon /> },
        contextValue.userType===0&&contextValue.select_trener===null&&{ text: 'Данные ведомства', icon: <AccountCircleIcon /> },
        contextValue.userType===0&&contextValue.select_trener===null&&{ text: 'Печать', icon: <ApprovalOutlinedIcon /> },
        ((contextValue.userType===0&&contextValue.select_trener!==null)||(contextValue.userType!==0&&contextValue.trener_id===contextValue.select_trener)||contextValue.super_user)&&{ text: 'Роспись', icon: <DrawOutlinedIcon /> },
        // (contextValue.userType!==0&&contextValue.trener_id===contextValue.select_trener)&&{ text: 'Роспись', icon: <DrawOutlinedIcon /> },
        { text: 'Фото', icon: <AccountBoxOutlinedIcon /> },
        (contextValue.trener_id===contextValue.select_trener)&&{ text: 'Изменить пароль', icon: <PasswordOutlinedIcon /> },
        (contextValue.trener_id===contextValue.select_trener||contextValue.super_user)&&{ text: 'Изменить Email', icon: <AlternateEmailOutlinedIcon /> },
        contextValue.select_trener!==null&&{ text: 'Судейская категория', icon: <GavelOutlinedIcon /> },
        // Добавьте другие пункты меню по необходимости
    ].filter(Boolean);;
    const [selectedItem, setSelectedItem] = useState(contextValue.userType===0?'Данные ведомства':'Личные данные');
    const handleListItemClick = (item) => {
        setSelectedItem(item);
    };
    const list = () => (
        <List>
            {menuItems.map((item, index) => (
                <ListItem button key={item.text} onClick={() => handleListItemClick(item.text)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="body1" sx={{ display: { xs: 'none', md: 'block' } }}>
                        {item.text}
                    </Typography>
                </ListItem>
            ))}
        </List>
    );
    const [imageUrl, setImageUrl] = useState(`./uploads/users/${contextValue.select_trener?trener.user_id:contextValue.user_id}/stamp-crop.png?t=${new Date().getTime()}`);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const prov = Boolean(anchorEl);
    const id = prov ? 'mouse-over-popover' : undefined;
    const renderContent = () => {
        switch (selectedItem) {
                case 'Данные ведомства':
                return <>
                <TextField fullWidth sx={{my:1}} id="outlined-basic" value={orgData.school_name} label="Наименование организации" variant="outlined" name="family" onChange={e=>setOrgData({...orgData, school_name:e.target.value})}/>
                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={orgData.school_inn} label="ИНН организации" variant="outlined" name="family" onChange={e=>setOrgData({...orgData, school_inn:e.target.value})}/>
                        <Autocomplete
                        autoHighlight={true}
                            disableClearable
                            freeSolo
                            fullWidth sx={{my:1}} 
                            open={openList}
                            options={options}
                            inputValue={defaultInputValue }
                            onInputChange={handleInputChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Адрес" variant="outlined" name="championat_adress"/>)}
                            getOptionLabel={(option) =>
                                {if(option?.data!==undefined)
                                   return `${option?.data?.country!==null?option?.data.country:""}${option?.data?.region_with_type!==null?`, ${option?.data.region_with_type}`:""}${option?.data?.city!==null?`, ${option?.data.city}`:""}${option?.data?.street_with_type!==null?`, ${option?.data?.street_with_type}`:""}${option?.data?.house_type!==null?`, ${option?.data?.house_type}`:""}${option?.data?.house!==null?`,${option?.data?.house}`:""}`
                                    else return ""}}
                            onChange={(event, value)=>
                                {
                                    setOrgData({...orgData,school_adress:JSON.stringify(value)?.replaceAll("\\\"","")})}
                                }
                            filterOptions={(options, { inputValue }) =>
                                options.length>0 && options?.filter((option) => {
                                const inputWords = inputValue.toLowerCase().replace(/[.,%]/g, '').split(" ");
                                const optionWords = JSON.stringify(option.data).toLowerCase().split(" ");
                                // console.log(inputWords)
                                return inputWords.every((word) =>
                                optionWords.some((optionWord) => optionWord.includes(word))
                                );
                            })
                            }
                            />
                            <Button                
                            variant="contained"
                            sx={{my:1}}
                            fullWidth
                            onClick={handleSubmitSchoolData}
                        >Сохрранить изменения </Button>
                </>
            case 'Личные данные':
                return <>
                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={trener.trener_family} label="Фамилия" variant="outlined" name="family" onChange={e=>setTrener({...trener, trener_family:e.target.value})}/>
                        
                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={trener.trener_name} label="Имя" variant="outlined" name="name" onChange={e=>setTrener({...trener, trener_name:e.target.value})}/>

                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={trener.trener_otchestvo} label="Отчество" variant="outlined" name="otchestvo" onChange={e=>setTrener({...trener, trener_otchestvo:e.target.value})}/>
                        <Box
                            sx={{
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            > 
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker fullWidth sx={{my:1}} variant="outlined" value={dayjs(trener.trener_age)} label="Дата рождения" onChange={(x, event) => setTrener({...trener, trener_age:`${x.$y}-${x.$M+1}-${x.$D}`})}/>
                        </LocalizationProvider> 
                        
                        </Box>    
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(trener.trener)} onChange={(event,x)=>setTrener({...trener,trener:Number(x)})}/>} label="Тренер"
                        /> 
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(trener.sud)} onChange={(event,x)=>setTrener({...trener,sud:Number(x)})}/>} label="Может судить"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(trener.secret)} onChange={(event,x)=>setTrener({...trener,secret:Number(x)})}/>} label="Секретарь"
                        />                  
                        <Button     
                            fullWidth sx={{my:1}}           
                            variant="contained"
                            key="editTrener"
                            onClick={handleSubmit}
                        >Сохранить </Button>
                        <Button       
                            fullWidth sx={{my:1}}         
                            variant="contained"
                            key="Cancel"  
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button> 
                </>
                case 'Печать':
                    return <>
                    {!open&&<><input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="stamp-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Grid container spacing={2}>
                      <Grid item alignContent={'center'} alignItems={'center'} xs={4}>
                       <div style={{ width: 250, height: 250, backgroundColor: 'white', borderRadius: 0 }}>
                       <Avatar
                            onError={(e) => { e.target.src = './uploads/users/no_avatar.jpg'; }}
                            src={imageUrl+"?t=${new Date().getTime()"}
                            sx={{ width: 250, height: 250, marginTop: 2, borderRadius: 0, backgroundColor: 'white' }}
                        /></div>
                      </Grid>
                     
                        <Grid item xs={12}>
                      <label htmlFor="stamp-upload">
                        <Button fullWidth variant="contained" component="span">
                          Загрузить печать
                        </Button>
                      </label>
                      </Grid>
                      </Grid></>}
                      {open&&<AvatarPreviewDialog open={open} preview={preview} handleClose={handleClose} type={"stamp"} id={contextValue.user_id} selectedFile={selectedFile} imageHeight/>}</>
                case 'Роспись':
                    return <>
                    {!open&&<><input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="stamp-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Grid container spacing={2}>
                      <Grid item alignContent={'center'} alignItems={'center'} xs={12}>
                      <div style={{ width: 250, height: 250, backgroundColor: 'white', borderRadius: 0,overflow: 'visible' }}>
                            <img
                                      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} 
                                      width='300px'
                                      src={`${imageUrl}?t=${new Date().getTime()}`}
                                      loading="lazy"
                                      onError={(e) => { e.target.style.display = 'none'; }}
                                    />
                        <Typography>Для загрузки личной подписи используйте скан или фотографию на белом фоне</Typography>
                        </div>

                        </Grid>
                     
                        <Grid item xs={12}>
                      <label htmlFor="stamp-upload">
                        <Button fullWidth variant="contained" component="span">
                          Загрузить роспись
                        </Button>
                      </label>
                      </Grid>
                      </Grid></>}
                      {open&&<AvatarPreviewDialog open={open} preview={preview} handleClose={handleClose} type={"stamp"} id={trener.user_id} selectedFile={selectedFile} />}</>
                case 'Фото':
                    return <>{!open&&<><input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="avatar-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Grid container spacing={2}  direction={{xs:"column-reverse",md:"row"}}>
                      <Grid item xs={12}>
                      <Avatar
                onError={(e) => { e.target.src = './uploads/users/no_avatar.jpg'; }}
                key={`ua` + contextValue.user_avatar_key}
                src={`./uploads/users/${contextValue.select_trener ? trener.user_id : contextValue.user_id}/avatar_full.png?t=${new Date().getTime()}`}
                style={{
                  width: 'auto',
                  height: '500px',
                  borderRadius: '0',
                  objectFit: 'cover',
                  filter: 'brightness(1.1) contrast(1.2)',
                  border: '2px solid #ccc',
                }}
            />
          
                      <Avatar onerror={'./uploads/users/no_avatar.jpg'} key={`uam`+contextValue.user_avatar_key} src={`./uploads/users/${contextValue.select_trener?trener.user_id:contextValue.user_id}/avatar_crop.png?t=${new Date().getTime()}`} style={{
                          width: '75px',
                          height: '75px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          filter: 'brightness(1.1) contrast(1.2)',
                          border: '2px solid #ccc',   
                            marginLeft:'100px',
                            marginTop:'-50px',
                        }}/>
                        </Grid>
                        <Grid item xs={12}>
                      <label htmlFor="avatar-upload">
                        <Button fullWidth variant="contained" component="span">
                          Загрузить фото
                        </Button>
                      </label>
                      </Grid>
                      </Grid></>}
                      {open&&<AvatarPreviewDialog open={open} preview={preview} handleClose={handleClose} type={"users"} selectedFile={selectedFile}/>}</>
                      case "Изменить Email":
                        return <>
                       
                                           <Typography>Изменить email</Typography>
                                              
                                               <TextField
                                                   required
                                                   fullWidth
                                                   sx={{my:1}}
                                                   // type="email"
                                                   // label="email"
                                                   value={trener.user_email}
                                                   onChange={e=>
                                                   {
                                                    setUserData({...user_data, user_email:e.target.value})                                                    
                                                    setTrener({...trener,user_email:e.target.value})
                                                    }
                                                    }
                                               />
                                               <Button                
                                           variant="contained"
                                           key="editTrener"
                                           sx={{my:1}}
                                           fullWidth
                                           onClick={handleSubmitEmail}
                                       >Изменить email </Button></>     
                                           
                                               
                                               case "Изменить пароль": return <>
                                               <Typography>Сменить пароль</Typography>
                                               <TextField
                                   required
                                   fullWidth
                                   sx={{my:1}}
                                   type="password"
                                   label="Старый пароль"
                                   onChange={e=>setPass({...pass, old_password:e.target.value})}
                               />
                                                                   <TextField
                                   required
                                   fullWidth
                                   sx={{my:1}}
                                   type="password"
                                   label="Новый пароль"
                                   onChange={e=>setPass({...pass, new_password:e.target.value})}
                               />
                               <TextField
                                   fullWidth
                                   sx={{my:1}}
                                   required
                                   type="password"
                                   label="Повторите новый пароль"
                                   onChange={e=>setPass({...pass, new_password_check:e.target.value})}
                               />
                               <Button                
                                           variant="contained"
                                           key="editTrener"
                                           sx={{my:1}}
                                           fullWidth
                                           onClick={handleSubmitPassword}
                                       >Изменить пароль </Button>
                                               </>
                                               case "Судейская категория":
                                                return <>
                                                

                            <Typography>Добавить судейскую категорию</Typography>
                            <Box
                            sx={{
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            >  <Autocomplete
                            disablePortal
                                key={sud_categ_types.sud_categ_id}                                
                                value={selected_sud_categ}
                                id="sud_categ_types"
                                name='sud_categ_types'
                                autoHighlight={true}
                                options={sud_categ_types}
                                getOptionLabel={(option) =>`${option?.sud_categ_name}`}
                                onChange={(event, value) => {
                                    setSelectSudCateg(value);
                                    setSudCateg({...sud_categ, sud_categ_type_id:value?.sud_categ_id})
                                       
                                }}
                                renderInput={(params) => <TextField {...params} label="Судейская категория"                        
                                />}
                            />
                            
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker  variant="outlined"  value={selectedDate} label="Дата присвоения" onChange={(x, event) =>{
                                setSelectedDate(x);
                                 setSudCateg({...sud_categ,sud_categ_date_start:`${x.$y}-${x.$M+1}-${x.$D}`})}}/>
                        </LocalizationProvider>
                        
                    <TextField fullWidth id="outlined-basic"  label="Приказ" value={sud_categ.sud_categ_prikaz} variant="outlined" name="prikaz" onChange={e=>setSudCateg({...sud_categ, sud_categ_prikaz:e.target.value})}/>
                    
                    <TextField fullWidth  id="outlined-basic"  label="Организация" value={sud_categ.sud_categ_org} variant="outlined" name="org" onChange={e=>setSudCateg({...sud_categ, sud_categ_org:e.target.value})}/>
                   
                    <Button  
                            sx={{my:1}}
                            fullWidth              
                            variant="contained"
                            key="AddSudCateg"
                            onClick={AddSudCateg}
                        >Добавить судейскую категорию </Button>
                        <Button  
                            sx={{my:1}}
                            fullWidth              
                            variant="outlined"
                            key="ClearSudCateg"
                            onClick={()=>ClearSudCateg()}

                        >Очистить </Button>
                        </Box>
                    <TableContainer sx={{  height: "300px", overflow: "auto"}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>            
                            <TableCell align="center">Судейская категория</TableCell>
                            <TableCell align="center">Дата присовения</TableCell>
                            <TableCell align="center">Приказ</TableCell> 
                            <TableCell align="center">Удалить</TableCell>
                        </TableRow>
                        </TableHead>
                    <TableBody>  
                    {
            sud_categs?.map((r,i)=>(
              <TableRow
              key={r.sud_categ_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                    <TableCell wrap align="center">{i+1}</TableCell>
                    <TableCell wrap align="center">{r.sud_categ_name}</TableCell>
                    <TableCell wrap align="center">{r.sud_categ_date_start}</TableCell>
                    <TableCell wrap align="center">
                        {`${r.sud_categ_prikaz}, ${r.sud_categ_org}`}
                        </TableCell> 
                    <TableCell wrap align="center" size="medium">
                    <IconButton  aria-label="delete" 
                  onClick={()=>deleteSudCateg(r.sud_categ_id)} 
                  size="large"
                  sx={{minWidth:10}}>
                      <DeleteOutlineOutlinedIcon />
                  </IconButton>
                  </TableCell>
                    </TableRow>))}
                    </TableBody>
                    </Table>
                    </TableContainer></>
                                                                   default:
                return <Typography variant="h6">Выберите элемент из меню</Typography>;
        }
    };
    return (       
        <Back sx={{width:{xs:"400",md:"900"}}}> 
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <Grid container columns={12}>
            <Grid item xs={2} md={4} sx={{borderRight: "0.3px solid black"}}>
            <Grid container columns={12}>
            <CssBaseline />
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Avatar onerror={'./uploads/users/no_avatar.jpg'} key={`uam`+contextValue.user_avatar_key} src={`./uploads/users/${contextValue.select_trener?trener.user_id:contextValue.user_id}/avatar_crop.png?t=${new Date().getTime()}`} sx={{
                  width: {xs:'40px', md:'100px'},
                  height: {xs:'40px', md:'100px'},
                  align: 'center',
                  borderRadius: {xs:'50%', md:'15%'},
                  objectFit: 'cover',
                  filter: 'brightness(1.1) contrast(1.2)',
                  border: '2px solid #ccc',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                />
                  <Popover
                id={id}
                open={prov}
                anchorEl={anchorEl}
                onClose={handleMouseLeave}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    pointerEvents: 'none',
                }}
            >
                <Box
                    component="img"
                    src={`./uploads/users/${contextValue.select_trener ? trener.user_id : contextValue.user_id}/avatar_full.png?t=${new Date().getTime()}`}
                    alt="User Avatar"
                    sx={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '300px', // максимальная ширина поповера
                        maxHeight: '400px', // максимальная высота поповера
                    }}
                />
            </Popover>
                </Grid>
                <Grid item xs={12}>
                {list()}  
                </Grid>
                </Grid>
                </Grid>
                
            <Grid item xs={10} md={8}  sx={{p:1}}>
                <form onSubmit="return false"> 
                {renderContent()}
                </form>
            </Grid>
        </Grid>     
        
        </Back> 
     );
}
 
export default TrenerPage;