
import React from "react";
import axios from 'axios';
import { useContext,useEffect,useState } from "react";
import {Context} from "../../context";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import "./style.css";

const AddSostav = (props) => {
    const contextValue=useContext(Context)
    const [values,setValues] = useState ({
        sostav_type_id:'',
        sostav_sportsmen_1:'',
        sostav_sportsmen_2:'',
        sostav_sportsmen_3:'',
        sostav_sportsmen_4:'',
        school_id:contextValue.school_id
    })
    
    const [sostav_types, setSostavTypes] =useState([])
    const [sportsmens_girls, setSportsmensGirl] =useState([])
    const [sportsmens_boys, setSportsmensBoy] =useState([])
    const [select_sostav_type, setSelectSostavType] =useState(0)
    let can=true;
    useEffect(()=>{
        setSelectSostavType('0'); 
    },[contextValue.setOpenAlert])


    useEffect(()=>
    {
      axios.get(`/api/sportsmens/girls_nosostav/${contextValue.school_id}`)
      .then(res=>{setSportsmensGirl(res.data)})
      .catch(err=>console.log(err)) 
    },[contextValue.setOpenAlert]);


    

    useEffect(()=>
    {
      axios.get(`/api/sportsmens/boys_nosostav/${contextValue.school_id}`)
      .then(res=>{setSportsmensBoy(res.data)})
      .catch(err=>console.log(err)) 
    },[contextValue.setOpenAlert]);


    useEffect(()=>
    {
      axios.get('/api/sostavs/types')
      .then(res=>{setSostavTypes(res.data)})
      .catch(err=>console.log(err)) 
    },[]);


    function compareAllValues() {

        let a=[values.sostav_sportsmen_1,values.sostav_sportsmen_2]
        if(values.sostav_sportsmen_3)
        {
            a.push(values.sostav_sportsmen_3)
            if(values.sostav_sportsmen_4)
                a.push(values.sostav_sportsmen_4)
        }
        for (var i = 0; i < a.length; i++) {
            for (var j = i+1; j < a.length; j++) {
               if (a[j] === a[i] && a[j]!=='') { return false }
            }
        }   
        return true;
   }

   function checknullAllValues() {
    let can=true;
    switch (select_sostav_type.toString()) {
        case '2':  
            if(values.sostav_sportsmen_1==='') can=false;
                else if(values.sostav_sportsmen_2==='') can=false;
                    else if(values.sostav_sportsmen_3==='') can=false;
            return can;
        case '3':
            if(values.sostav_sportsmen_1==='') can=false;
                else if(values.sostav_sportsmen_2==='') can=false;
            return can;
        case '4':   
            if(values.sostav_sportsmen_1==='') can=false;
                else if(values.sostav_sportsmen_2==='') can=false;
                    else if(values.sostav_sportsmen_3==='') can=false;
                        else if(values.sostav_sportsmen_4==='') can=false;
             return can;
        case '5':   
            if(values.sostav_sportsmen_1==='') can=false;
                else if(values.sostav_sportsmen_2==='') can=false;  
            return can;
        case '6':   
            if(values.sostav_sportsmen_1==='') can=false;
                else if(values.sostav_sportsmen_2==='') can=false;
            return can;               
    }
}


  
        
        
   
   const handleChange = (event) => {
    handleChangeType(event.target.value)
  };


    function handleChangeType(type)
    {
        setValues({...values, sostav_type_id:type,
            sostav_sportsmen_1:'',
            sostav_sportsmen_2:'',
            sostav_sportsmen_3:'',
            sostav_sportsmen_4:''},
            setSelectSostavType(type))
    }
    const [open, setOpen] = useState(false);
    function handleSubmit()
    {
            can=true;
            checkInput();
            
            if(!open && can)
            {
                setOpen(true)
                axios.post("/api/sostavs/add",values)
                    .then(res=>{
                        if(res.data.Status==="Состав добавлен")
                        {                            
                            contextValue.setTypeMsg("success")
                            contextValue.setMsg("Состав добавлен")
                            contextValue.setOpenAlert(true)                            
                            setTimeout(props.setActive(false), 10000);                         
                        }
                        else alert(res.data.Error);
                        setOpen(false)  
                    })
                    .catch(err =>alert(err));
            }
            else return false;                    
    }


    function typeForm(type)
    {
        switch (type.toString()) {
            case '2': 
                return (
                <>                   
                        <Autocomplete
                            autoHighlight={true}
                            id="TOP"                            
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_girls}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_1:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Верхняя" />}
                            
                        />
                        <Autocomplete
                            autoHighlight={true}
                            id="MIDDLE"                            
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_girls}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_2:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Средняя" />}
                        />
                        <Autocomplete
                            autoHighlight={true}
                            id="BOTTOM"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_girls}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_3:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Нижняя" />}
                        />
                </>
                )
            case '3':
                return (
                <>                   
                <Autocomplete
                    autoHighlight={true}
                            id="TOP"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_boys}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_1:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Верхний" />}
                        />
               <Autocomplete
               autoHighlight={true}
                            id="BOTTOM"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_boys}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_2:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Нижний" />}
                        />
                </>
                )
            case '4':
                return (
                <>                   
                <Autocomplete
                autoHighlight={true}
                            id="TOP"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_boys}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_1:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Верхний" />}
                        />
               <Autocomplete
               autoHighlight={true}
                            id="MIDDLE"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_boys}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_2:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Средний" />}
                        />
                
                        <Autocomplete
                        autoHighlight={true}
                            id="BOTTOM1"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_boys}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_3:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Нижний №1" />}
                        />
                
                        <Autocomplete
                        autoHighlight={true}
                            id="BOTTOM2"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_boys}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_4:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Нижний №2" />}
                        />
                </>
                
                )
            case '5':
                return (
                <>                   
                <Autocomplete
                autoHighlight={true}
                            id="TOP"                            
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_girls}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_1:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Верхняя" />}
                        />
                <Autocomplete
                autoHighlight={true}
                            id="BOTTOM"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_boys}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_2:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Нижний" />}
                        />
                </>
                )
            case '6':
                   return (
                   <>                   
                   <Autocomplete
                   autoHighlight={true}
                            id="TOP"                            
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_girls}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_1:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Верхняя" />}
                        />
                   <Autocomplete
                   autoHighlight={true}
                            id="BOTTOM"
                            sx={{my:1}}
                            name='selectsportsmen'
                            freeSolo
                            options={sportsmens_girls}
                            getOptionLabel={(option) =>`${option?.sportsmen_family} ${option?.sportsmen_name} ${option?.sportsmen_otchestvo}`}
                            onChange={(event, value) => setValues({...values, sostav_sportsmen_2:value?.sportsmen_id})}
                            renderInput={(params) => <TextField {...params} label="Нижняя" />}
                        />
                   </>
                   )
            }
    }

      function checkInput()
      {
           if(select_sostav_type==='0')
           {
               can=false;
               contextValue.setMsg("Не выбран вид состава") 
              
           }
           else if(!compareAllValues())
           {
               can=false;
               contextValue.setMsg("Один и тот же спортсмен выбран больше одного раза")
           }
           else if(!checknullAllValues())
           {
               can=false;
               contextValue.setMsg("Выбраны не все спортсмены")
       }
       if(!can)
       {
        contextValue.setTypeMsg("error")
        contextValue.setOpenAlert(true)
        }
   }
      
      
    return (           
        <div className="p-3 bg-white">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit="return false" >
               <Box
                            sx={{
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            >              
                       
                       <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="sostav-type-lable">Вид</InputLabel>
                        <Select
                            labelId="sostav-type-lable"
                            id="demo-simple-select"
                            label="ВИД"
                            value={select_sostav_type}
                            onChange={handleChange}
                            >
                                {sostav_types.map((sostav_type,i)=>
                                    (
                                        <MenuItem value={sostav_type?.sostav_type_id}>{sostav_type?.sostav_type_name}</MenuItem>
                                    ))}
                        </Select>
                        <FormHelperText>Выберите вид состава</FormHelperText>
                        </FormControl>
                        </Box>
                        <Box
                            sx={{
                                padding:'10px',
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            >
                        {
                            typeForm(select_sostav_type)                    
                        } 
                        </Box>
                        <Box
                            sx={{
                                
                                paddingInline:'10px',
                                mt:1,
                                mb:-1,
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(2, 1fr)'
                            }}
                            >

                        <Button                
                            variant="contained"
                            key="addsosav"
                            disabled={open}
                            onClick={handleSubmit}
                        >Добавить </Button>


                        <Button                
                            variant="contained"
                            key="cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>
                        
                        
                    </Box>
                       
            </form>
        </div>

      );
}
 
export default AddSostav;