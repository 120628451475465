import { useState } from 'react';
import { useAudioPlayer } from 'react-use-audio-player';
import { Button, Dialog, DialogContent, DialogTitle, Slider } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import {CircularProgress } from '@mui/material';
import Draggable from 'react-draggable';
import "./../../styles/main.css"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {styled } from '@mui/material';
import Slide from '@mui/material/Slide';
import Paper, { PaperProps } from '@mui/material/Paper';
import ReactAudioPlayer from 'react-audio-player';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MusicPlayer = ({open, onClose, musicName}) => {

   const [musicPath, setMusicPath] = useState('');
   
   useEffect(()=>{
    musicName&&setMusicPath(`/uploads/musics/${musicName}`)},[musicName])// Состояние для отслеживания открытости диалогового окна
  // const { 
  //   togglePlay, 
  //   ready, 
  //   loading, 
  //   playing, 
  //   playbackRate, 
  //   muted, 
  //   volume, 
  //   duration, 
  //   played, 
  //   playedSeconds, 
  //   seek, 
  //   updateVolume, // Исправлено: вместо setVolume теперь updateVolume
  // } = useAudioPlayer({ 
  //   src: `/uploads/musics/${musicName}`, // Путь к вашему аудиофайлу на сервере
  //   format: 'mp3',
  //   autoplay: true,
  //   loop: true,
  //   html5: true,
  // });

 

  return (
    <React.Fragment>     
    <Dialog 
    fullWidth={true}
    minWidth={600}
    sx={{height:'auto'}}
    style={{zIndex:'2'}}
    maxWidth={'80vw'}
    open={open} 
    PaperComponent={PaperComponent}
    TransitionComponent={Transition}
    aria-labelledby="draggable-dialog-title"
      keepMounted
      onClose={onClose}
      >
      <Box
    sx={{
      display: { xs: 'flex', sm: 'none' }, // скрыть на xs, показывать на sm и больших экранах
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    }}
  >
    <IconButton
      sx={{ width: 50, marginBottom:'-25px', marginLeft: 'auto' }} // Обратите внимание на правильное написание
      aria-label="close"
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  </Box>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Box
                          sx={{
                              display: 'flex',
                              alignContent:'flex-start',
                              alignItems:'flex-start', 
                              justifyContent:'space-between'              
                          }}
                          > 
                           <Typography
                           
                      variant="h3"
                      sx={{ my:1,
                      display: { xs: 'flex', md: 'flex' },
                      
                      fontFamily: 'Poppins',
                      textAlign:'center',
                      color: 'inherit',
                      textDecoration: 'none',
                      }}
                  >{"Музыка"}
                      </Typography>
                      <IconButton sx={{ width:50, display: { xs: 'none', sm: 'flex'} }} aria-label="close" onClick={onClose}>
                      <CloseIcon />
      </IconButton>
      </Box>
      </DialogTitle>
      <DialogContent>
      {musicName&&musicPath}
      {musicName&&
      <ReactAudioPlayer
        src={musicPath}
        autoPlay
        controls
      />}
      {/* {(!ready && !loading) &&
     <p>Ошибка при загрузке файла.</p>
  }

  {(loading) &&
     <p>Загрузка...</p>
  } */}
        {/* <Button onClick={togglePlay}>{playing ? 'Пауза' : 'Воспроизвести'}</Button>
        <Slider 
          value={playedSeconds} 
          max={duration} 
          onChange={(event, newValue) => seek(newValue)}
        />
        <Slider 
          value={volume * 100} 
          min={0} 
          max={100} 
          onChange={(event, newValue) => {
            updateVolume(newValue / 100);
          }}
        /> */}
      </DialogContent>
    </Dialog>    
    </React.Fragment>
  );
};

export default MusicPlayer;