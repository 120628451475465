
import * as React from 'react';
import { Dialog, DialogContent, styled } from '@mui/material';
import Slide from '@mui/material/Slide';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useRef, useEffect,useContext } from 'react';
import {Context} from "./../../context";
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import "./../../styles/main.css"

const CustomScrollbar = styled(DialogContent)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f9f9fd', // Цвет дорожки
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#a1b1eb', // Цвет бегунка
    borderRadius: '3px',        // Округление бегунка
    border: '1px solid #a1b1eb' // Границы бегунка
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function FormDialog(props) {
  const contextValue=useContext(Context)
  const scrollRef = useRef(null);
  useEffect(() => {
    if (props.isActive && scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Прокрутка до начала
    }
  }, [props.isActive]);

  const handleClose = () => {
    contextValue.setOrderMemberID(null)
    props.setActive(false);
  };

  return (
    <React.Fragment>     
      <Dialog 
      fullWidth={true}
      minWidth={350}
      sx={{height:'auto'}}
      style={{zIndex:'2'}}
      maxWidth={props.width}
      open={props.active} 
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      aria-labelledby="draggable-dialog-title"
        keepMounted
        onClose={handleClose}
        >
        <Box
      sx={{
        display: { xs: 'flex', sm: 'none' }, // скрыть на xs, показывать на sm и больших экранах
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      }}
    >
      <IconButton
        sx={{ width: 50, marginBottom:'-25px', marginLeft: 'auto' }} // Обратите внимание на правильное написание
        aria-label="close"
        onClick={() => props.setActive(false)}
      >
        <CloseIcon />
      </IconButton>
    </Box>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Box
                            sx={{
                                display: 'flex',
                                alignContent:'flex-start',
                                alignItems:'flex-start', 
                                justifyContent:'space-between'              
                            }}
                            > 
                             <Typography
                             
                        variant="h3"
                        sx={{ my:1,
                        display: { xs: 'flex', md: 'flex' },
                        
                        fontFamily: 'Poppins',
                        textAlign:'center',
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >{props.title}
                        </Typography>
                        <IconButton sx={{ width:50, display: { xs: 'none', sm: 'flex'} }} aria-label="close" onClick={()=>props.setActive(false)}>
                        <CloseIcon />
        </IconButton>
        </Box>
        </DialogTitle>
          
        {/* <DialogContent > */}
        <CustomScrollbar dividers ref={scrollRef}>
          <props.children active={props.active} setActive={props.setActive} orders={props.orders} setOrders={props.setOrders}/>
        </CustomScrollbar>
        {/* </DialogContent>        */}
      </Dialog>
    </React.Fragment>
  );
}