import React, { useState, useRef,useEffect  } from "react";
// import React, { useState, useCallback, } from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./ImageEditor.module.css";
import {
  BiRotateLeft,
  BiRotateRight,
  BiReset,
  BiDownload
} from "react-icons/bi";
import ControlButton from "./ControlButton.jsx";

const ImageEditor = ({selectedFile,setCompletedCropCanvas}) => {
  const [scale, setScale] = useState(1);
  const cropperRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  const onRotate = (direction) => () => {
    let angle = 0;
    let angleConfig = {
      left: -30,
      right: 30
    };
    angle = angleConfig[direction] ?? 0;
    cropperRef.current.cropper.rotate(angle);
  };

  const onScale = (e) => {
    const scaleValue = parseFloat(e.target.value);
    setScale(scaleValue);
    cropperRef.current.cropper.scale(scaleValue);
  };

  const onReset = () => {
    const cropper = cropperRef.current.cropper;
    cropper.reset();
    setScale(1);
  };

  const onDownload = () => {
    const data = cropperRef.current.cropper.getCroppedCanvas();
    console.log(data);
    data.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "React Image Editor.jpg";
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  const onSave = () => {
    const data = cropperRef.current.cropper.getCroppedCanvas();
    console.log(data);
    setCompletedCropCanvas(data);
    
  };



  useEffect(()=>{onImageUpload()},[selectedFile])

  function onImageUpload() {
    const reader = new FileReader();

    reader.onload = (e) => {
      setImageSrc(e.target.result);
      cropperRef.current.cropper.reset();
    };

    reader.readAsDataURL(selectedFile);
  };

  return (
    <div className={styles.imageEditor}>
      <div>
        <Cropper
          src={imageSrc}
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={4 / 3}
          guides={false}
          ref={cropperRef}
          crop={onSave}
        />
        <div className={styles.controlsBlock}>
          <ControlButton tooltip="Повернуть на лево" onClick={onRotate("left")}>
            <BiRotateLeft size={30} />
          </ControlButton>
          <ControlButton tooltip="Повернуть на право" onClick={onRotate("right")}>
            <BiRotateRight size={30} />
          </ControlButton>
          <div className={styles.scaleFieldBlock}>
            <input
              type="range"
              min="0.2"
              max="2"
              step="0.2"
              value={scale}
              aria-label="scale"
              id="scale"
              onChange={onScale}
            />
            <label htmlFor="scale">Масштаб</label>
          </div>
          <ControlButton tooltip="Сброс" onClick={onReset}>
            <BiReset size={30} />
          </ControlButton>
          <ControlButton tooltip="Скачать изображение" onClick={onDownload}>
            <BiDownload size={30} />
          </ControlButton>
        </div>
        {/* <UploadImageButton onImageUpload={onImageUpload} /> */}
      </div>
    </div>
  );
}

export default ImageEditor;
