
import "./style.css";
import { NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import {Context} from "../../../context";
import {useContext, useState } from "react";
import FormDialog from "../../../ModalWindow/Dialog/FormDialog";
import TrenerPage from "../../../pages/TrenerPage";


const AdminNavbar = (props) => {
  const contextValue=useContext(Context) 
  const[editTrenerActive,seteditTrenerActive]=useState(false)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
   
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    

    return (
    <>
    {editTrenerActive&&<FormDialog title={"Профиль пользователя "+contextValue.name} FormDialog width={'md'}  active={editTrenerActive} setActive={seteditTrenerActive} children={TrenerPage}/>}
    <AppBar position="static" style={{ background: '#010147' }} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Container maxWidth="xl">
      <Toolbar disableGutters >
     

      <Box sx={{ flexGrow: 1,alignItems: 'center', display: { xs: 'flex', md: 'flex' } }}>        
      
      <Typography
            variant="h5"
            noWrap
            align="left"
            component="a"
            href="/"
            sx={{
              mt: 3,
              ml:-2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Poppins',
              textAlign: 'center',
              fontWeight: 600,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',

            }}
          >
            Панель администратора
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        
        {
        props.isAuth && 
                    <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <AdminPanelSettingsIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >            
                    <MenuItem 
                    key="users" 
                    component={NavLink}
                    to="/users"
                    >
                    <Typography textAlign="center">Пользователи</Typography>
                    </MenuItem>  
                     <MenuItem 
                    key="gallery" 
                    component={NavLink}
                    to="/gallery"
                    >
                    <Typography textAlign="center">Галерея</Typography>
                    </MenuItem>  
                    <MenuItem 
                    key="links" 
                    component={NavLink}
                    to="/links"
                    >
                    <Typography textAlign="center">Ссылки</Typography>
                    </MenuItem> 
                    <MenuItem 
                    key="sudroles" 
                    component={NavLink}
                    to="/sudroles"
                    >
                    <Typography textAlign="center">Судейские должности</Typography>
                    </MenuItem>
                                                    
                
              </Menu>
              
              </>
              }
            </Box>  
        </Box>
          <Box sx={{ flexGrow: 0,alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
              {props.isAuth && 
             
              <>              
              <Button
                key="users"
                component={NavLink}
                to="/users"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >Пользователи</Button>
              <Button
                key="gallery"
                component={NavLink}
                to="/gallery"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >Галерея</Button>
              <Button
                key="links"
                component={NavLink}
                to="/links"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >Ссылки</Button>
               <Button
                key="sudroles"
                component={NavLink}
                to="/sudroles"
                sx={{ my: 2, color: 'white', display: 'block' , 
                '&.active': {
                  backgroundColor:'white',
                  color: "black",
                }}}
              >Суд. должности</Button>
              </>}
          </Box>
        
          
      </Toolbar>
      
    </Container>

</AppBar>
    </>
       );
}
 
export default AdminNavbar;