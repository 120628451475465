import { useEffect, useState ,useContext} from "react";
import {Context} from "../../context";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Box, Grid } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from "notistack";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { json } from "react-router-dom";
import { faIR } from "@mui/x-date-pickers";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));

const AddSud = (props) => {

    const { enqueueSnackbar } = useSnackbar()

    let newSportsmens=[]
    const contextValue=useContext(Context)
    const [orders, setOrders] =useState([])
    const [treners, setTreners] =useState([])
    const [selectTrener, setSelectTrener] =useState(null)
    const [selectRole, setSelectRole] =useState(null)
    const [roles, setRoles] =useState([])
    const [values,setValues] = useState ({
        championat_sud_id:null,
        championat_id:contextValue.championat_id,
        trener_id:null,
        sud_role_id:props.orders?.type!=="soprovod"?props.orders.selectSud?.sud_role_id:null
    })

    const [withoutinorder,setWithoutInOrder]=useState(false)


    useEffect(()=>
    {
      if(props.active)
      {
        console.log(props.orders)
        setOpen(true)
        getTreners();
        getRoles();
        if(props.orders?.selectSud!==null)
        {
          props.orders?.type!=="soprovod"?
          setValues({...values,
            championat_sud_id:props.orders?.selectSud.championat_sud_id,
            trener_id:props.orders?.selectSud.trener_id,
            sud_role_id:props.orders?.selectSud.sud_role_id
          })
          :
          setValues({...values,
            sud_role_id:0
          })
        }
        
      }
      else Clear()
    },[props.active,contextValue.sud_role_id]);


    useEffect(()=>
    {      
      console.log(values)
    },[values]);

    function Clear()
    {
      setValues({...values,
        championat_sud_id:null,
        championat_id:contextValue.championat_id,
        trener_id:null,
        sud_role_id:props.orders?.type!=="soprovod"?null:0})
        setSelectRole(null)
        setSelectTrener(null)
        contextValue.setChampionatSudID(null)
    }


    
    
  
    async function getTreners() {      
      let response = await axios.get(`/api/treners/all_treners`);
      let treners = response.data;// Don't need await her
      if(contextValue.championat_sud_id!==null && props.orders!==null)
        {
          setSelectTrener(response.data[response.data?.findIndex(x=>x.trener_id===props.orders.selectSud.trener_id)])
        }
        props.orders?.type!=="soprovod"?
        setTreners(treners)
        :
        setTreners(treners.filter(x=>x.school_id===contextValue.school_id));
      
  };
    async function getRoles() {      
      let response = await axios.get(`/api/suds/roles`);
      let roles = response.data;// Don't need await her
      if(contextValue.championat_sud_id!==null && props.orders!==null)
        {
          setSelectRole(response.data[response.data?.findIndex(x=>x.sud_role_id===props.orders.selectSud.sud_role_id)])
        }
      setRoles(roles);
      setOpen(false)
  };
  
  function check()
  {
    if(values.trener_id===null)
      {
        contextValue.setTypeMsg("error")
        contextValue.setMsg(`Сотрудник не выбран`)
        contextValue.setOpenAlert(true)  
        return false;
      }
      else if(values.sud_role_id===null)
        {
          contextValue.setTypeMsg("error")
          contextValue.setMsg(`Судейская должность не выбрана`)
          contextValue.setOpenAlert(true)  
          return false;
        }
        else if(props.orders?.suds?.filter(x=>x.trener_id===values.trener_id && x.sud_role_id===values.sud_role_id).length>0)
          {
            contextValue.setTypeMsg("error")
            contextValue.setMsg(`Такой сотруник уже пристутствует`)
            contextValue.setOpenAlert(true)  
            return false;
          }
    return true;   
  }

  function handleCancel()
  {
    Clear()
    props.setActive(false)
  }

  function handleSubmit()
  {
          if(check())
          {
              axios.post("/api/suds/add",values)
                  .then(res=>{
                      if(res.data.Status==="Судья добавлен")
                      {                            
                          contextValue.setTypeMsg("success")
                          props.orders?.type!=="soprovod"?contextValue.setMsg(contextValue.championat_sud_id!==null?"Изменения сохранены":"Судья добавлен")
                          :contextValue.setMsg("Представитель команды добавлен")

                          contextValue.setOpenAlert(true)
                          props.setActive(false)
                          Clear();
                      }
                      else alert(res.data.Error);
                  })
                  .catch(err =>alert(err));
          }                   
  }

  const [open, setOpen] = useState(false);     
    return (
      <SnackbarProvider maxSnack={10}>
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
        <Box sx={{ padding:3, flexGrow: 1 }}>
        <Grid container spacing={2} columns={{ xs: 12, md: 18 }} alignItems={"center"} alignContent={"center"}>
          {contextValue.sud_categ_id===null&&<Grid item xs={12} md={8} alignContent={"center"}>
                <FormControlLabel sx={{ p: 1 }}
                  control={<Checkbox checked={withoutinorder} onChange={(event,x)=>setWithoutInOrder(x)}/>}
                  label="Не отображать добавленных"
                />
                </Grid>}
                <Grid item xs={12} md={props.orders?.type!=="soprovod"?12:18} alignContent={"center"}>
            <Autocomplete
                    id="trener"
                    disabled={contextValue.championat_sud_id!==null}
                    value={selectTrener} 
                    options={treners.filter(x=>x.archive===0)}                            
                    getOptionLabel={(option) =>`${option?.FIO}`}
                    onChange={(event, value) => {
                      setSelectTrener(value)
                      setValues({...values, trener_id:value.trener_id})}
                      }
                      renderInput={(params) => <TextField {...params} label="Тренеры"/>}
                    /> 
                </Grid>
                <Grid item xs={12} md={6} alignContent={"center"}>
            {props.orders?.type!=="soprovod"&&<Autocomplete
                    id="roles"
                    value={selectRole} 
                    options={roles.filter(x=>x.sud_role_id>0)}                            
                    getOptionLabel={(option) =>`${option?.sud_role_name}`}
                    onChange={(event, value) => {
                      setSelectRole(value)
                      setValues({...values, sud_role_id:value.sud_role_id})}
                      }
                      renderInput={(params) => <TextField {...params} label="Должность"/>}
                    />} 
                </Grid>
                </Grid>
                <Grid container spacing={2} my={1} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={12} md={4} alignContent={"center"}>
                      {contextValue.championat_sud_id===null?
                               <Button 
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />} 
                                  size="middle"
                                  key="addSostavInOrder"
                                  onClick={()=>{handleSubmit()}}
                                  fullWidth                                  
                                >Добавить</Button>
                                :
                                <Button 
                                  variant="contained"
                                  startIcon={<SaveOutlinedIcon />} 
                                  size="middle"
                                  key="addSostavInOrder"
                                  onClick={()=>{handleSubmit()}}
                                  fullWidth                                  
                                >Сохранить</Button>
                          }
                </Grid>
                <Grid item xs={12} md={4} alignContent={"center"}>
                <Button 
                                  variant="contained"
                                  startIcon={<CloseIcon />} 
                                  size="middle"
                                  key="clear"
                                  onClick={()=>Clear()}
                                  fullWidth                                  
                                >Очистить</Button> 
                </Grid>
                
                <Grid item xs={12} md={4} alignContent={"center"}>
                <Button 
                                  variant="contained"
                                  startIcon={<CloseIcon />} 
                                  size="middle"
                                  key="addSostavInOrder"
                                  onClick={()=>
                                    {handleCancel()}}
                                  fullWidth                                  
                                >Отменить</Button> 
                </Grid>
                </Grid>
        </Box>
        </SnackbarProvider> 
      );
}
 
export default AddSud;