import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: 600,
        marginTop: 10,
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    dialog: {
        maxWidth: '50%',
        margin: '0 auto',
    },
});

const SudRoles = () => {
    const classes = useStyles();
    const [sudRoles, setSudRoles] = useState([]);
    const [values, setValues] = useState(
        {
            sud_role_id:null,
            sud_role_name:"",
            approving:0
        });
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Получить данные из таблицы
    useEffect(() => {
        fetchDataFromServer().then(data => {
            setSudRoles(data);
        });
    }, []);

    const fetchDataFromServer = async () => {
        const response = await axios.get('/api/suds/getAllRoles');
        return response.data;
    };

    // Функции редактирования
    const handleEditClick = (id) => {
        const selectedRow = sudRoles.find(row => row.sud_role_id === id);
        // setEditData(selectedRow);
        setValues({...values,
            sud_role_id:id,
            sud_role_name:selectedRow.sud_role_name,
            approving:selectedRow.approving
        })
        setIsModalOpen(true);     
    };


    // Функции удаления
    const handleDeleteClick = (id) => {
        const confirmed = window.confirm("Вы уверены, что хотите удалить запись?");
        if (confirmed) {
            axios.post(`/api/suds/deleteRole/${id}`).then(response => {
                setSudRoles(sudRoles.filter(role => role.sud_role_id !== id));
            });
        }
    };

    // Функции добавления
    const handleAddNewClick = () => {
        setValues({
            sud_role_id:null,
            sud_role_name:"",
            approving:0
        })
        setIsModalOpen(true);
    };
    const handleChange = (event) => {
        setValues({
          ...values,approving: event.target.checked?1:0,
        });
      };

    const handleSubmit = () => {
        axios.post('/api/suds/addRole', values).then(response => {
            if(sudRoles.filter(role => role.sud_role_id === response.data.sud_role_id).length===0)
            {
                const newSud={
            sud_role_id:response.data.sud_role_id,
            sud_role_name:values.sud_role_name,
            approving:0 }   
            setSudRoles([...sudRoles, newSud])}
            else{
                const updatedSudRoles = sudRoles.map((role) => {
                if (role.sud_role_id === response.data.sud_role_id) {
                  // Обновляем имя роли
                  return { ...role, sud_role_name: values.sud_role_name, approving:values.approving };
                }
                
                // Оставляем роль без изменений, если условие не выполнено
                return role;
              });
              setSudRoles(updatedSudRoles)}
              
        });
        setIsModalOpen(false);
    };

    // Рендеринг компонентов
    const renderTableRows = () => {
        return sudRoles.map((row,i) => {
            return (
                <TableRow key={row.sud_role_id}>
                    <TableCell component="th" scope="row">{i+1}</TableCell>
                    <TableCell>{row.sud_role_name}</TableCell>
                    <TableCell>{Boolean(row.approving)?'Да':'Нет'}</TableCell>
                    <TableCell>
                        <IconButton edge="end" aria-label="Edit" onClick={() => handleEditClick(row.sud_role_id)}>
                            <EditOutlinedIcon/>
                        </IconButton>
                        <IconButton edge="end" aria-label="Delete" onClick={() => handleDeleteClick(row.sud_role_id)}>
                            <DeleteOutlineOutlinedIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        });
    };

    const renderModalContent = () => {
        return (
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} className={classes.dialog}>
                <DialogTitle sx={{mb:1}} id="form-dialog-title">Добавить новую роль</DialogTitle>
                <DialogContent>
                    <TextField label="Название роли" defaultValue={values.sud_role_name} onChange={event => setValues({ ...values, sud_role_name: event.target.value })} fullWidth/>
                    <FormControl component="fieldset">
                        <FormControlLabel
                        value="end"
                        control={<Switch color="primary" checked={Boolean(values.approving)} onChange={handleChange}/>}
                        label="Утверждает"
                        labelPlacement="end"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {/* <Button variant="contained" color="primary" disabled={!editMode} onClick={handleUpdateClick}>Обновить</Button> */}
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Добавить/Обновить</Button>
                    <Button variant="contained" color="secondary" onClick={() => setIsModalOpen(false)}>Отмена</Button>
                    <IconButton aria-label="close" onClick={() => setIsModalOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <div className="App">
         <Button 
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />} 
                                  size="large"
                                  key="addChampionat"
         onClick={handleAddNewClick} fullWidth
                                  style={{display:'flex',maxHeight: '100px', minHeight: '50px',borderRadius:0,marginBottom:'10px'}}
                                >Добавить новую роль</Button>
           
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Название роли</TableCell>
                            <TableCell>Утверждает</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
            {renderModalContent()}
        </div>
    );
};

export default SudRoles;