import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import Button from '@mui/material/Button';
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChampionatMembers from "./ChampionatMembers";
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
const PrintButton = styled(Fab)(({ theme }) => ({
        position: 'absolute',
          top: theme.spacing(10),
          right: theme.spacing(2)
      }));



const ChampionatStatisticsIndivid = (props) => {
    
  const contextValue=useContext(Context)
  const [ages,setAges]=useState([]);
  const [razryads,setRazryads]=useState([])
  const [gender,setGender]=useState([])
  const [filtredOrders,setFiltredOrders]=useState([])
  const [sportsmensCount,setSportsmensCount]=useState('');
  
  const[showOrders,setShowOrders]=useState(false)


    useEffect(()=>
    {      
      setAges(Array.from(new Set(props.orders?.sort((a, b)=>a.age-b.age).map((obj)=>obj.age))))
      setRazryads(Array.from(new Set(props.orders?.sort((a, b)=>a.razryad_id-b.razryad_id).map(obj=>obj.razryad_short_name))))
      setGender(Array.from(new Set(props.orders?.sort((a, b)=>a.sportsmen_gender-b.sportsmen_gender).map(obj=>obj.sportsmen_gender))))
      setSportsmensCount(getSportsmenCount(props.orders))
    },[props.orders])


 
      function getCount(a,r,t)
      {
        let count=props.orders?.filter((o)=>o.age===a && (contextValue.championat.championat_style_name!=="КПН"?o.razryad_short_name===r:o.kpn===r) && o.sportsmen_gender===t).length;
        return count>0?count:'';
      }

      function showFiltredOrders(a,r,t)
      {
        setFiltredOrders(props.orders?.filter((o)=>o.age===a && (contextValue.championat.championat_style_name!=="КПН"?o.razryad_short_name===r:o.kpn===r) && o.sportsmen_gender===t));
        setShowOrders(true);

      }

      function getCountAll(t)
      {
        
        let count=props.orders?.filter((o)=>o.sportsmen_gender===t).length;
        return count>0?count:'';
      }
      function getSportsmenCount(mass)
      {        
        return mass.length;
      }
      const componentRef = useRef();
      const handlePrint = useReactToPrint({
              content: () => componentRef.current,
            });

    return (       
        
                      <>
                      <Helmet>
        <title>Статистика</title>
        <meta name="description" content={`Статистика соревнования ${contextValue.championat.championat_name}`} />
        </Helmet>
        <PrintButton color="secondary" aria-label="add" onClick={handlePrint}>
                  <PrintOutlinedIcon />
                </PrintButton>  
                      <FormDialog title="Участники" width={'lg'} active={showOrders} setActive={setShowOrders} children={ChampionatMembers} orders={filtredOrders}/>
                      <Stack direction='row' justifyContent='center'>
                              <Typography
                                    variant="h6"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                      <Typography>Статистика соревнования (<strong>Спортсменов</strong>:{sportsmensCount})</Typography>
                                    </Typography>
                        </Stack>
                        <TableContainer ref={componentRef} component={Paper}>
                              <Table size="small" aria-label="simple table">
                                <TableHead>                               
                                  <TableRow>
                                    <TableCell align="center">{contextValue.championat.championat_style_name!=="КПН"?"Разряд":"Уровень подготовки"}/пол</TableCell>           
                                    {gender.length>0 && gender?.map((t)=>(                                    
                                      <TableCell align="center">{t}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {ages.length>0 && ages?.map((a)=>(
                                  <>
                                  <TableRow>
                                    <TableCell align="center" colSpan={gender.length+1}><strong>{a}</strong></TableCell>                                    
                                  </TableRow> 
                                  { Array.from(
                                    new Set(props.orders?.sort((a, b)=>(contextValue.championat.championat_style_name!=="КПН"?a.kpn-b.kpn:a.razryad_id-b.razryad_id)).filter((o)=>o.age===a)?.map(obj=>(contextValue.championat.championat_style_name!=="КПН"?obj.razryad_short_name:obj.kpn))))?.map((r)=>(
                                    <TableRow sx={{maxHeight:15}}>
                                    <TableCell align="center">{r}</TableCell> 
                                    {gender?.map((t)=>(                                    
                                      <TableCell align="center">{getCount(a,r,t) && <Button variant="contained" onClick={()=>showFiltredOrders(a,r,t)}>{getCount(a,r,t)}</Button>}</TableCell>
                                    ))}                                     
                                    </TableRow>                                                                       
                                    ))}
                                      
                                    </>                                
                                  ))}
                                  <TableRow>
                                      <TableCell align="center"><strong>Итого</strong></TableCell> 
                                      {gender?.map((t)=>(                                    
                                        <TableCell align="center">
                                          <strong>{getCountAll(t)}</strong>                                         
                                            </TableCell>
                                      ))}  
                                    </TableRow>
                                </TableBody>
                                </Table>
                                </TableContainer>    
                      </>                      
     );
}
 
export default ChampionatStatisticsIndivid;