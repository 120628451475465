import "./../../styles/main.css"
import Home from "./../../pages/Home";
import Sportsmens from "./../../pages/Sportsmens";
import Sostavs from "./../../pages/Sostavs";
import {useContext, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import AdminNavbar from "./../../pages/Admin/adminNavbar/AdminNavbar";
import Navbar from "./../../components/navbar/Navbar";
import axios from 'axios';
import {Context} from "./../../context";
import Treners from "./../../pages/Treners";
import Championats from "./../../pages/Champoinats";
import Orders from "./../../pages/Orders";
import OrderPage from "./../../pages/OrderPage";
import ChampionatPage from "./../../pages/ChampionatPage";
import ChampionatGraph from "./../../pages/ChampionatGraph";
import Links from "./../../pages/Admin/Links";
import SudRoles from "./../../pages/Admin/SudRoles";
import Gallery from "./../../pages/Admin/Gallery";
import Users from "./../../pages/Admin/Users";
import Password from "./../../pages/Password";
import { SnackbarProvider } from 'notistack';

const Router = () =>  {  
    const contextValue=useContext(Context)
    
    useEffect(()=>
    {
        if(contextValue.auth===null)
        {            
            axios.defaults.withCredentials = true;
            axios.post("/")
                        .then(res=>{                    
                          if(res.data.Status.includes("Авторизация успешна"))
                          {
                            console.log(res.data)
                            contextValue.setUserId(res.data.user_id)
                            contextValue.setUserEmail(res.data.user_email) 
                            contextValue.setName(res.data.name)
                            contextValue.setSchoolID(res.data.school_id)
                            contextValue.setUserType(res.data.user_type_id)
                            contextValue.setTrenerID(res.data.trener_id)
                            contextValue.setSuperUser(res.data.super_user)
                            contextValue.setAuth(true);

                          }
                          else
                          { 
                            contextValue.setAuth(false);
                          }
                        })
                        .catch(err=>{return false})
        } 
    })
    return (
      <SnackbarProvider maxSnack={10}>
        {contextValue.super_user===1&&<AdminNavbar isAuth={contextValue.auth}/>}
       <Navbar isAuth={contextValue.auth}/>
        <Routes> 
          <Route path={"/"} element={<Home /> }/>          
          <Route path={"/sostavs"} element={contextValue.auth? <Sostavs  />:<Home /> }/>
          <Route path={"/Password/:link"} element={<Password />}/>
          <Route path={"/sportsmens"} element={contextValue.auth? <Sportsmens />:<Home /> }/>
          {contextValue.userType!==2&&<Route path={"/treners"} element={contextValue.auth? <Treners />:<Home /> }/>}
          <Route path={"/championats"} element={contextValue.auth? <Championats />:<Home /> }/>
          <Route path={"/championat"} element={contextValue.auth? <ChampionatPage />:<Home /> }/>
          <Route path={"/orders"} element={contextValue.auth? <Orders />:<Home /> }/> 
          <Route path={"/order"} element={contextValue.auth? <OrderPage />:<Home /> }/>    
          <Route path={"/graph"} element={contextValue.auth? <ChampionatGraph />:<Home /> }/>
          {contextValue.super_user===1&&
          <>
          <Route path={"/links"} element={contextValue.auth? <Links />:<Home /> }/> 
          <Route path={"/gallery"} element={contextValue.auth? <Gallery />:<Home /> }/>    
          <Route path={"/users"} element={contextValue.auth? <Users />:<Home /> }/>
          <Route path={"/sudroles"} element={contextValue.auth? <SudRoles />:<Home /> }/>
          </>
          }
        </Routes>
      </SnackbarProvider>
       
      );
}
 
export default Router;