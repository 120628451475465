import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, CircularProgress } from '@mui/material';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // Для стилей аннотаций
import "./../../styles/main.css"
import axios from "axios";
// Библиотеки для конвертации различных форматов в PDF
import { pdfjs } from 'react-pdf';

import * as React from 'react';
import {styled } from '@mui/material';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useRef, useContext } from 'react';
import {Context} from "./../../context";
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import "./../../styles/main.css"

const CustomScrollbar = styled(DialogContent)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f9f9fd', // Цвет дорожки
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#a1b1eb', // Цвет бегунка
    borderRadius: '3px',        // Округление бегунка
    border: '1px solid #a1b1eb' // Границы бегунка
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const PdfViewerModal = ({ open, onClose, type, id, extension }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [numPages, setNumPages] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`
  // Функция для получения PDF с сервера
  const fetchAndConvertPdf = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get(`/api/compositions/download/${type}/${id}/${extension}`,{
        responseType: 'blob', // Используйте arraybuffer вместо blob
        headers: { 'Accept': 'application/octet-stream' },
    });
    
    if (response.status === 200) {

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const type = blob.type;
console.log(blob)
console.log(type) 
  // Если файл уже в формате PDF, просто создаем URL
  const url = URL.createObjectURL(blob);
  setPdfUrl(url);

      
    }
    } catch (error) {
      console.error('Ошибка при загрузке PDF:', error.message);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchAndConvertPdf();
    }
  }, [open]);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  const [state,setState] = useState([]);

  const onDocumentComplete = (pages) => {
    setState({ page: 1, pages });
  };

  const handlePrevious = () => {
    setState({ page: state.page - 1 });
  };

  const handleNext = () => {
    setState({ page: state.page + 1 });
  };
  const scrollRef = useRef(null);
  useEffect(() => {
    if (open && scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Прокрутка до начала
    }
  }, [open]);

  const renderPagination = (page, pages) => {
    let previousButton = (
      <li className="previous" onClick={handlePrevious}>
        <a href="#">
          <i className="fa fa-arrow-left" /> Previous
        </a>
      </li>
    );
    if (page === 1) {
      previousButton = (
        <li className="previous disabled">
          <a href="#">
            <i className="fa fa-arrow-left" /> Previous
          </a>
        </li>
      );
    }
    let nextButton = (
      <li className="next" onClick={handleNext}>
        <a href="#">
          Next <i className="fa fa-arrow-right" />
        </a>
      </li>
    );
    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="#">
            Next <i className="fa fa-arrow-right" />
          </a>
        </li>
      );
    }
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  };
  let pagination = null;
  if (state.pages) {
    pagination = renderPagination(state.page, state.pages);
  }
  
  return (
    <React.Fragment>     
    <Dialog 
    fullWidth={true}
    minWidth={600}
    sx={{height:'auto'}}
    style={{zIndex:'2'}}
    maxWidth={'80vw'}
    open={open} 
    PaperComponent={PaperComponent}
    TransitionComponent={Transition}
    aria-labelledby="draggable-dialog-title"
      keepMounted
      onClose={onClose}
      >
      <Box
    sx={{
      display: { xs: 'flex', sm: 'none' }, // скрыть на xs, показывать на sm и больших экранах
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    }}
  >
    <IconButton
      sx={{ width: 50, marginBottom:'-25px', marginLeft: 'auto' }} // Обратите внимание на правильное написание
      aria-label="close"
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  </Box>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Box
                          sx={{
                              display: 'flex',
                              alignContent:'flex-start',
                              alignItems:'flex-start', 
                              justifyContent:'space-between'              
                          }}
                          > 
                           <Typography
                           
                      variant="h3"
                      sx={{ my:1,
                      display: { xs: 'flex', md: 'flex' },
                      
                      fontFamily: 'Poppins',
                      textAlign:'center',
                      color: 'inherit',
                      textDecoration: 'none',
                      }}
                  >{"Просмотр"}
                      </Typography>
                      <IconButton sx={{ width:50, display: { xs: 'none', sm: 'flex'} }} aria-label="close" onClick={onClose}>
                      <CloseIcon />
      </IconButton>
      </Box>
      </DialogTitle>
        
      {/* <DialogContent > */}
      <CustomScrollbar dividers ref={scrollRef}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {extension==='pdf'&& !errorMessage && pdfUrl && (
              <><Document style={{width:'100%'}} file={pdfUrl} onDocumentComplete={onDocumentComplete}
          onLoadError={() => {
            console.log("CALLED");
          }}
          onLoadProgress={() => {
            console.log("PRGREOSS");
          }}
          page={state.page}
        >

          <Page style={{width:'100%'}} renderTextLayer={false} pageNumber={1}  scale={2}/>
        </Document>
        {pagination}
              </>
            )}
            {extension!=='pdf'&& !errorMessage && pdfUrl && 
            <>
            <img src={pdfUrl} alt="" style={{ width: '100%' }} loading="lazy" />
            </>
            }
          </>
        )}
      </CustomScrollbar>
    </Dialog>
    </React.Fragment>
  );
};

export default PdfViewerModal;