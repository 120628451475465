import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import FormDialog from "../ModalWindow/Dialog/FormDialog"
import AddSud from "../components/addSud/AddSud";
import {Helmet} from "react-helmet";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo from "./../img/Logo.png"
import Avatar from '@mui/material/Avatar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Stack from '@mui/material/Stack';

const ChampionatSuds = (props) => {
        const contextValue=useContext(Context)
        const[editSud,setEditSud]=useState(false)
        const [selectSud,setSelectSud]=useState(null)
        const [print,setPrint]=useState(false)
        const [open, setOpen] = useState(false);  
        const scores=
        {
          1:'Не удовлетворительно',
          2:'Удовлетворительно',
          3:'Хорошо',
          4:'Отлично'
        }
        const [originalWidth, setOriginalWidth] = useState(null);
    const handleImageLoad = (e) => {
      // Получаем оригинальную ширину изображения
      setOriginalWidth(e.target.naturalWidth);
    };
        useEffect(()=>{console.log(props)},[props])

        useEffect(()=>{
                if(selectSud!==null){
                contextValue.setChampionatSudID(selectSud?.championat_sud_id)
                setEditSud(true)}
        },[selectSud])
       
        function deleteSud(championat_sud_id)
        {            
            axios.post(`/api/suds/delete/${championat_sud_id}`)
            .then(res=>{
                console.log(res.data.Status)
                if(res.data.Status==="Судья удален")
                {
                  contextValue.setTypeMsg("success")
                  contextValue.setMsg(`Судья удален`)
                  contextValue.setOpenAlert(true)
                }
                else console.log(res.data.Status);
            })
            .catch(err =>alert(err))
        } 
        useEffect(()=>{print?(handlePrint()):setOpen(false)},[print])

        const handlePrint = useReactToPrint({
                content: () => componentRef.current,
                pageStyle: "@page { size: auto; margin: 0mm; }",
                contentStyle: { transform: 'scale(0.8)', transformOrigin: 'top left' },
                onBeforeGetContent:()=>{setOpen(true)},
                onAfterPrint:()=>{setPrint(false)}
              });
              
        const componentRef = useRef();

        async function setScore(championat_sud_id,score)
        {
          axios.post(`/api/suds/setScore/${championat_sud_id}/${score}`)
          .then(res=>{console.log(res.data.Status)
                if(res.data.Status==="Оценка сохранена")
                {
                  contextValue.setTypeMsg("success")
                  contextValue.setMsg(`Оценка сохранена`)
                  contextValue.setOpenAlert(true)
                }
                else console.log(res.data.Status);
            })
            .catch(err =>alert(err))
        } 
        const [currentday, setCurrentDay]=useState({
          day:'1',
          start_time:null,
          brigade:'0'})
          useEffect(()=>{console.log(currentday)},[currentday])
        
          async function GenerateOneBrigade()
          {

          }
          async function GenerateTwoBrigade()
          {
            
          }
    return (
        <>
                        {editSud && <FormDialog title={selectSud!==null?'Изменение судьи':'Добавление судьи'} width='md' active={editSud} setActive={setEditSud} children={AddSud} orders={{suds:props.orders,selectSud:selectSud}}/>}
                        <Helmet>
                                <title>Заявка</title>
                                <meta name="description" content="Редактирование заявки" />
                        </Helmet> 
                        <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                        >
                        <CircularProgress color="inherit" />
                        </Backdrop>
                        <Button onClick={()=>{contextValue.setChampionatSudID(null);setSelectSud(null);setEditSud(true)}}>Добавить судью</Button>       
                        <Button onClick={()=>{setPrint(true)}}>Печать</Button>
                        <Button disabled={props.orders.length<10} onClick={()=>{GenerateOneBrigade()}}>Распределить на 1 бригаду</Button>
                        <Button disabled={props.orders.length<20} onClick={()=>{GenerateTwoBrigade()}}>Распределить на 2 бригады</Button>
                        
                        
                        
                        
                        {contextValue.championat.championat_style_name==="Парно-групповое" &&
                                      <Paper fullWidth
                            sx={{
                              // width:'95%',
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center',
                                padding:1              
                            }}
                            >
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    fullWidth
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    День соревнования
                                    </Typography>
                                    </Stack>
                    <ToggleButtonGroup
                                color="primary"
                                value={currentday.day}
                                exclusive
                                onChange={(event, newValue)=>setCurrentDay({...currentday,day:newValue})}
                                aria-label="Platform"
                                sx={{
                                    display: 'grid',                    
                                    gridAutoColumns: '1fr',
                                    gridTemplateColumns: 'repeat(3, 1fr)'            
                                }}
                                >
                                <ToggleButton value='1'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    1
                                    </Typography>
                                    </ToggleButton>
                                <ToggleButton value='2'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>2</Typography></ToggleButton>
                                    <ToggleButton value='3'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>3</Typography></ToggleButton>
                                  </ToggleButtonGroup>   
                                  </Paper>}
                        <Box alignItems={'center'} width={'95%'} sx={{display:print?'block':'none',p:3 }} ref={componentRef} pageStyle={{size:'landscape'}}>
                        
                        
                        <TableContainer sx={{ my:1}} >
                          <Table style={{ width: "100%"}}  aria-label="sticky table" size='small'  sx={{
                                    '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                    },
                                  }}
                                > 
                               <TableBody> 
                                <TableRow>
                                  <TableCell align='center' style={{width:100,border:0}} rowSpan={2}>
                                    <img width={100} src={logo}></img> 
                                  </TableCell>
                                  <TableCell colSpan={4} style={{width: '90%', border:0}} align='center'>
                                      <Typography fontSize={24}><strong>{contextValue.championat.championat_name}</strong></Typography>
                                  </TableCell>
                                </TableRow>
                                    <TableRow>
                                    <TableCell style={{ border:0}} align='center'>
                                      <Typography fontSize={10}>{contextValue.championat.championat_start_date}-{contextValue.championat.championat_end_date}</Typography>
                                    </TableCell>
                                    <TableCell style={{width: '50%',border:0}} align='center'>
                                      {/* <Typography fontSize={10}>{contextValue.championat.championat_start_date}-{contextValue.championat.championat_end_date}</Typography> */}
                                    </TableCell>
                                    <TableCell  align='center' style={{ border:0}}>
                                        <Typography fontSize={10}> {`${JSON.parse(contextValue.championat.championat_adress)?.data?.country!==null?JSON.parse(contextValue.championat.championat_adress)?.data.country:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.region_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.city!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.city}`:""}`}</Typography>
                                    </TableCell>
                                    </TableRow>
                                </TableBody>
                                </Table>
                                </TableContainer>  
                                <TableContainer sx={{ my:1}} >
                          <Table style={{ width: "100%"}}  aria-label="sticky table" size='small'  sx={{
                                    '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                    },
                                  }}
                                > 
                              <TableHead>
                                <TableRow>
                                  <TableCell rowSpan={1} sx={{ border: 1 }}>№</TableCell>         
                                  <TableCell align="center"
                                  rowSpan={1} sx={{ border: 1 }} >ФИО</TableCell>
                               <TableCell align="center"
                                  rowSpan={1} sx={{ border: 1 }}>Должность</TableCell>
                                       <TableCell align="center"
                                rowSpan={1} sx={{ border: 1 }}>Категория</TableCell>
                                       <TableCell align="center"
                                 rowSpan={1} sx={{ border: 1 }}>Регион</TableCell>
                                 <TableCell align="center"
                                  rowSpan={1} sx={{ border: 1 }}>№ бригады</TableCell>
                                       <TableCell align="center"
                                  rowSpan={1} sx={{ border: 1 }}>Оценка</TableCell>
                                  
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {props.orders?.filter(x=>x.sud_role_id!==0)?.map((s,i)=>(
                                    <TableRow
                                    key={s.order_member_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                                  >
                                   <TableCell rowSpan={1} sx={{ border: 1, width: '25px' }} align="center">
                                          <p>{i+1}</p>           
                                  </TableCell>      
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.FIO}</p>           
                                  </TableCell>   
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.sud_role_name}</p>           
                                  </TableCell> 
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.sud_categ_name===null?"Без судейской категории":s.sud_categ_name}</p>           
                                  </TableCell>
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{`${JSON.parse(s?.user_address)?.data?.region_with_type!==null?`${JSON.parse(s?.user_address)?.data.region_with_type}`:""}${JSON.parse(s?.user_address)?.data?.city!==null?`, ${JSON.parse(s?.user_address)?.data.city}`:""}`}</p>           
                                     
                                  </TableCell>
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{s.brigade}</p>           
                                  </TableCell>
                                  <TableCell rowSpan={1} sx={{ border: 1 }}align="left">
                                          <p>{scores[s.score]}</p>           
                                  </TableCell>
                                    </TableRow>))}          
                                  </TableBody>
                            </Table>
                          </TableContainer>
                          <TableContainer sx={{ my:1, breakAfter:'page',overflow: 'visible'}} >
                  <Table style={{ width: "95%"}}  aria-label="sticky table" size='small'  sx={{
                            '& .MuiTableCell-sizeSmall': {
                              padding: '0px',
                            },
                            overflow: 'visible'
                          }}
                        > 
                         <TableBody>  
                         <TableRow sx={{overflow: 'visible'}}>
    <TableCell style={{ width: '20%', border: 0, verticalAlign: 'middle' }} sx={{ whiteSpace: "pre" }} align='center'>
        {/* <Typography fontSize={10}> Должность, судейская категория </Typography> */}
    </TableCell>
    <TableCell style={{ width: '30%', border: 0, position: 'relative', height: '0px', verticalAlign: 'middle', overflow: 'visible' }} rowSpan={10}>
        <img
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: originalWidth? `${originalWidth}px `: 'auto', // Используем оригинальную ширину
              height: 'auto', // Сохраняем пропорции
            }}
            src={`./uploads/users/${contextValue.championat.user_id}/stamp-crop.png?t=${new Date().getTime()}`}
            loading="lazy"
            onLoad={handleImageLoad}
            onError={(e) => { e.target.style.display = 'none'; }}
        />
    </TableCell>
    <TableCell style={{ width: '30%', border: 0,  verticalAlign: 'middle' }} align='center'>
        {/* <Typography fontSize={10}> Подпись </Typography> */}
    </TableCell>
    <TableCell style={{ width: '20%', border: 0,  verticalAlign: 'middle' }} align='center'>
        {/* <Typography fontSize={10}> ФИО, регион, ведомство </Typography> */}
    </TableCell>
</TableRow>
                            
                              {props.orders.filter(sud=>sud.approving===1)?.map(sud=><TableRow>
    <TableCell style={{ border: 0, verticalAlign: 'middle', height: '30px' }} align='right' sx={{ whiteSpace: "pre" }}>
        <Typography fontSize={12}>{`${sud.sud_role_name}\n${sud.sud_categ_name === null ? "Без судейской категории" : sud.sud_categ_name}`}</Typography>
    </TableCell>
    <TableCell style={{ border: 0, position: 'relative', overflow: 'visible', height: '30px', verticalAlign: 'middle' }} align='left'>
        <img
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} // Центрирование изображения
            width='144px'
            src={`./uploads/users/${sud?.user_id}/stamp-crop.png?t=${new Date().getTime()}`}
            loading="lazy"
            onError={(e) => { e.target.style.display = 'none'; }}
        />
    </TableCell>
    <TableCell style={{ border: 0, verticalAlign: 'middle', height: '30px' }} align='right' sx={{ whiteSpace: "pre" }}>
        <Typography fontSize={12}>
            {`${sud?.FIO}\n${JSON.parse(sud?.user_address)?.data?.region_with_type !== null ? `${JSON.parse(sud?.user_address)?.data.region_with_type}` : ""}${JSON.parse(sud?.user_address)?.data?.city !== null ? `, ${JSON.parse(sud?.user_address)?.data.city}` : ""}`}
        </Typography>
    </TableCell>
</TableRow>)}
                             
                              
                          </TableBody>                    
                     </Table>
                     </TableContainer>
                          </Box>
                          {!print&&<TableContainer component={Paper}>
                          <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                        '& .MuiTableCell-sizeSmall': {
                                        padding: '1px',
                                        },
                                        }}
                                > 
                                <TableHead>
                                  <TableRow>
                                    <TableCell>№</TableCell>  
                                    <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}></TableCell>       
                                    <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>ФИО</TableCell>
                                 <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Должность</TableCell>
                                         <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Категория</TableCell>
                                         <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Регион</TableCell>
                                         <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>№ бригады</TableCell>
                                         <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>Оценка</TableCell>
                                        <TableCell width='100' align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}></TableCell>
                                        
                                       </TableRow>
                                </TableHead>
                                <TableBody>
                                {props.orders?.filter(x=>x.sud_role_id!==0 && x.day===Number(currentday.day))?.map((s,i)=>(
                                      <TableRow
                                      key={s.order_member_id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                     <TableCell sx={{
                                            whiteSpace: "pre-wrap", width: '25px' }} align="center">
                                            <p>{i+1}</p>           
                                    </TableCell>    
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <Avatar
                                             onerror={'./uploads/users/no_avatar.jpg'} src={`./uploads/users/${s.user_id}/avatar_crop.png`} style={{
                                              width: '40px',
                                              height: '40px',
                                              borderRadius: '50%',
                                              objectFit: 'cover',
                                              filter: 'brightness(1.1) contrast(1.2)',
                                              border: '2px solid #ccc',
                                            }}/>           
                                    </TableCell>     
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap", width: '150px' 
                                            }}align="left">
                                            <p>{s.FIO}</p>           
                                    </TableCell>   
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p>{s.sud_role_name}</p>           
                                    </TableCell> 
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p>{s.sud_categ_name===null?"Без судейской категории":s.sud_categ_name}</p>           
                                            </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-line", width: '150px' 
                                            }}align="left">
                                             <p>{`${JSON.parse(s?.user_address)?.data?.region_with_type!==null?`${JSON.parse(s?.user_address)?.data.region_with_type}`:""}${JSON.parse(s?.user_address)?.data?.city!==null?`, ${JSON.parse(s?.user_address)?.data.city}`:""}`}</p>           
                                                
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap", width: '25px' }} align="center">
                                            <p>{s.brigade}</p>           
                                    </TableCell> 
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <InputLabel id="select_score">Оценка</InputLabel>
                                                <Select
                                                    labelId="select_score"
                                                    label="Оценка"
                                                    fontSize='10px'
                                                    value={s.score}
                                                    onChange={(event) => setScore(s.championat_sud_id, event.target.value)} // Получаем значение из события
                                                    >
                                                        {Object.entries(scores).map(([key, value]) => (
                                                            <MenuItem sx={{ fontSize: '0.8rem' }} key={key} value={key}>{value}</MenuItem> // Используем ключ как значение
                                                        ))}
                                                </Select>
                                                {/* <FormHelperText>Выберите оценку</FormHelperText> */}
                                                </FormControl>          
                                    </TableCell>
                                    

                                     <TableCell align="center">
                                        {i>1&&<><Tooltip title="Редактировать"> 
                                                <IconButton  aria-label="edit" onClick={()=>{setSelectSud(null);setSelectSud(s)}}>
                                        <EditOutlinedIcon />
                                        </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Удалить"> 
                                                <IconButton  aria-label="edit" onClick={()=>{deleteSud(s.championat_sud_id)}}>
                                                <CloseOutlinedIcon />
                                        </IconButton>
                                        </Tooltip></>}
                                        </TableCell>
                                      </TableRow>))}          
                                    </TableBody>
                              </Table>
                            </TableContainer> }
                            
        </>

        
     )
}
 
export default ChampionatSuds;