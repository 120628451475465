import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import "react-image-crop/dist/ReactCrop.css";
import { Button, Snackbar, Alert } from '@mui/material';

import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {Context} from "../context";
import {useContext} from "react";
import ImageEditor from "./../components/imageEditor/ImageEditor.jsx";

const AvatarPreviewDialog = ({props, open, handleClose,fileName, type,id, preview, selectedFile, user, imageHeight }) => {
  const contextValue=useContext(Context) 


  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  


const handleUpload = async () => {
  setLoading(true);

  type === 'stamp'&&removeWhiteBackground(completedCropCanvas);

  completedCropCanvas.toBlob(async (blob) => {
      if (!blob) {
          console.error('Failed to create a blob from the cropped image.');
          setLoading(false);
          return;
      }

      const formData = new FormData();
      const fileName = type === 'stamp' ? 'stamp_crop.png' : 'avatar_crop.png';
      formData.append('image', blob, fileName);

      let path = '/api/files'+ type === 'stamp' ? `/upload_stamp/${id}-${type}` : `/upload_avatar/${type}-${type === 'users' ? contextValue.user_id : contextValue.sportsmen}`;

      try {
          const response = await fetch(`${path}-crop`, {
              method: 'POST',
              body: formData,
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          if (type !== 'stamp') {
              if (type === 'users') {
                  contextValue.setUserAvatarCrop(URL.createObjectURL(blob));
              } else {
                  user.sportsmen_avatar_crop = URL.createObjectURL(blob);
              }
          }
      } catch (error) {
          console.error('Error uploading cropped image:', error);
      }

      // Загружаем полное изображение
      if(type!=='stamp'){
        try
         {
          const fullImageFormData = new FormData();
          fullImageFormData.append('image', selectedFile, 'avatar_full.png');
          const response = await fetch(`${path}-full`, {
              method: 'POST',
              body: fullImageFormData,
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          if (type !== 'stamp') {
              if (type === 'users') {
                  contextValue.setUserAvatar(preview);
              } else {
                  user.sportsmen_avatar = preview;
              }
          }

          
      } catch (error) {
          console.error('Error uploading full image:', error);
      } 
      }
      setLoading(false)
      handleClose()
  })
};

// Функция для удаления белого фона
const removeWhiteBackground = (canvas) => {
  console.log(canvas)
  const ctx = canvas.getContext('2d');
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  // Удаляем белый фон
  for (let i = 0; i < data.length; i += 4) {
      // Если цвет пикселя близок к белому, делаем его прозрачным
      if (data[i] > 200 && data[i + 1] > 200 && data[i + 2] > 200) {
          data[i + 3] = 0; // Прозрачный
      } else if (data[i] < 50 && data[i + 1] < 50 && data[i + 2] < 50) {
          data[i + 3] = 255; // Полностью непрозрачный
      }
  }
  
  // Возвращаем измененные данные на canvas
  ctx.putImageData(imageData, 0, 0);
};


const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
};




// const [completedCropBlob, setCompletedCropBlob] = useState(null);
const [completedCropCanvas, setCompletedCropCanvas] = useState(null);

// useEffect(()=>{console.log(setCompletedCropBlob)},[setCompletedCropBlob])
  return (
    <Dialog 
    open={open} 
    onClose={handleClose} 
    fullWidth={true}
    minWidth={350}
    sx={{height:'auto'}}
    style={{zIndex:'2'}}
    maxWidth={'md'}>
    <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
      </Snackbar>
      <DialogTitle>Предпросмотр</DialogTitle>
      <DialogContent>

        {preview && (
          <Grid container spacing={2} sx={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item alignContent={'center'} alignItems={'center'} xs={12}>
          <ImageEditor selectedFile={selectedFile} setCompletedCropCanvas={setCompletedCropCanvas}/>
            </Grid>
            
            </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Отмена
        </Button>
        <Button onClick={handleUpload} color="primary">
          Загрузить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarPreviewDialog;