
import React from "react";
import axios from 'axios';
import { useContext,useState,useEffect } from "react";
import {Context} from "../../context";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import 'dayjs/locale/ru';
import "./style.css";



const AddTrener = (props) => {
    const contextValue=useContext(Context)  
    const [values,setValues] = useState ({
        family:'',
        name:'',
        otchestvo:'',
        school_adress:contextValue.school.school_adress,
        age:null,
        email:'',
        password:'',
        passwordcheck:'',
        user_type:1,
        sud:'',
        trener:'',
        secret:''
    })
 
    useEffect(()=>{props.active&&setValues({
        family:'',
        name:'',
        otchestvo:'',
        school_adress:contextValue.school.school_adress,
        age:null,
        email:'',
        password:'',
        passwordcheck:'',
        user_type:1,
        sud:'',
        trener:'',
        secret:''
    })},[props.active])
    
    let can=true;

    function handleSubmit(event)
    {
        can=true;
        contextValue.setMsg('');
            checkInput();
            if(can)
             {
                values.password=gen_password(12)
                console.log(values)
                event.preventDefault();
                axios.post(`/api/users/registration/trener/${contextValue.school_id}`,values)
                .then(res=>{
                    if(res.data.Status==="Тренер добавлен")
                    {
                        contextValue.setTypeMsg("success")
                        contextValue.setMsg("Тренер добавлен")
                        contextValue.setOpenAlert(true)
                        setTimeout(props.setActive(false), 5000);
                    }
                    else 
                    {
                        contextValue.setTypeMsg("error")
                        contextValue.setMsg(res.data.Error)
                        contextValue.setOpenAlert(true)
                    }
                })
                .catch(err =>alert(err));
            }
    
}




    function checkInput()
    {         
         if(values.family==='')
         {
            contextValue.setMsg("Введите фамилию")
            can=false
         }
         else if(values.name==='')
         {
            contextValue.setMsg("Введите имя")
            can=false
         }      
         if(values.email==='')
            {
                can=false;
                contextValue.setMsg("Не введен Email") 
            }
            else
            {
             if (!validateEmail(values.email))  {
                 can=false;
                 contextValue.setMsg("Email не корректен") 
               }
            }
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
    function gen_password(len){
        var password = "";
        var symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+=";
        for (var i = 0; i < len; i++){
            password += symbols.charAt(Math.floor(Math.random() * symbols.length));     
        }
        return password;
    } 

        

        const validateEmail = (email) => {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
          };

    return (           
        <div className="p-3 bg-white">   
            
           
            <form onSubmit="return false">
            
                            <Box
                                sx={{
                                    display: 'grid',                    
                                    gridAutoColumns: '1fr',
                                    gap: 2,
                                    gridTemplateColumns: 'repeat(1, 1fr)',
                                    alignContent:'center',
                                    alignItems:'center'               
                                }}
                                > 
                        <TextField id="outlined-basic" label="Фамилия" value={values.family} variant="outlined" name="family" onChange={e=>setValues({...values, family:e.target.value})}/>
                        
                        <TextField id="outlined-basic" label="Имя" variant="outlined" value={values.name} name="name" onChange={e=>setValues({...values, name:e.target.value})}/>

                        <TextField id="outlined-basic" label="Отчество" variant="outlined" value={values.otchestvo} name="otchestvo" onChange={e=>setValues({...values, otchestvo:e.target.value})}/>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker variant="outlined" label="Дата рождения" onChange={(x, event) => setValues({...values, age:`${x.$y}-${x.$M+1}-${x.$D}`})}/>
                        </LocalizationProvider>
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(values.trener)} onChange={(event,x)=>setValues({...values,trener:Number(x)})}/>} label="Тренер"
                        /> 
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(values.sud)} onChange={(event,x)=>setValues({...values,sud:Number(x)})}/>} label="Может судить"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(values.secret)} onChange={(event,x)=>setValues({...values,secret:Number(x)})}/>} label="Секретарь"
                        /> 
                        <TextField
                    required
                    id="outlined-required"
                    value={values.email}
                    label="Email"
                    onChange={e=>setValues({...values,  email:e.target.value.toLowerCase().trim()})}
                />

                        <Button                
                            variant="contained"
                            key="AddTrener"
                            onClick={handleSubmit}
                        >Добавить </Button>
                        <Button                
                            variant="contained"
                            key="Cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>                       
                        
                        </Box>
                
              

            </form>
           
        </div>

      );
}
 
export default AddTrener;