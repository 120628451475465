import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        // Обновить состояние, чтобы следующий рендер показал запасной UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в журнал приложения
        console.error("Ошибка:", error, errorInfo);
    }

    handleClose = () => {
        this.setState({ hasError: false, error: null });
    };

    render() {
        const { hasError, error } = this.state;

        if (hasError) {
            return (
                <Dialog open={hasError} onClose={this.handleClose}>
                    <DialogTitle>Что-то пошло не так.</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">Произошла ошибка. Пожалуйста, проверьте детали ниже.</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Подробнее об ошибке</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">{error && error.toString()}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Назад
                        </Button>
                        <Button onClick={() => window.location.href = '/'} color="primary">
                            На главную
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;