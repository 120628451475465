import React from "react";
import axios from 'axios';

import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Context} from "../context";
import {useContext, useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const Login = (props) => {
    // let {user_id} = useParams()
    const contextValue=useContext(Context)
    const navigate = useNavigate();
    axios.defaults.withCredentials=true;
    const [values,setValues] = useState ({
        email:'',
        password:''
    })
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    const handleMouseUpPassword = (event) => {
      event.preventDefault();
    };
    function handleLogin(event)
    {
        axios.post("/api/auth/login",values)
        .then(res=>{
            if(res.data.Status==="Авторизация успешна")
            {
                console.log(res.data)
                axios.post("/")
                .then(res=>{
                  console.log(res.data)
                  if(res.data.Status.includes("Авторизация успешна"))
                  {
                    contextValue.setAuth(true)
                    contextValue.setUserEmail(res.data.user_email) 
                    contextValue.setUserId(res.data.user_id) 
                    contextValue.setSchoolID(res.data.school_id) 
                    contextValue.setTrenerID(res.data.trener_id)
                    contextValue.setUserType(res.data.user_type_id)
                    contextValue.setSuperUser(res.data.super_user)
                    contextValue.setTypeMsg("success")
                    contextValue.setMsg("Вход выполнен")
                    contextValue.setOpenAlert(true)
                    navigate('/sportsmens')
                    setTimeout(props.setActive(false), 5000); 
                  }
                  else
                  {
                    contextValue.setAuth(false)
                  }
                })
            }
            else alert(res.data.Error);
        })
        .catch(err =>alert(err));
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    function handleRestore(event)
    {
        if(values.email!=='' && values.email!==null && validateEmail(values.email))
       { event.preventDefault();
        axios.post(`/api/auth/password/restore_mail/${values.email}`)
        .then(res=>{
            if(res.data.Status==="На почту направлено письмо для восстановления пароля")
            {
                
                    contextValue.setTypeMsg("success")
                    contextValue.setMsg("На почту направлено письмо для восстановления пароля")
                    contextValue.setOpenAlert(true)
                  }
                  else
                  {
                    contextValue.setTypeMsg("error")
                    contextValue.setMsg(res.data.Error)
                    contextValue.setOpenAlert(true)
                  }
                })
            
        .catch(err =>alert(err));}
        else
        {
            contextValue.setTypeMsg("error")
                    contextValue.setMsg("Email не корректен")
                    contextValue.setOpenAlert(true)
        }
    }

    
    return ( 

                <Box
                sx={{
                    display: 'grid',                    
                    gridAutoColumns: '1fr',
                    gap: 2,
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    alignContent:'center',
                    alignItems:'center'               
                }}
                > 
<TextField
    required
    id="outlined-email"
    label="Email"
    type="email"
    name="email" // Добавлено для автозаполнения
    autoComplete="email" // Добавлено для автозаполнения
    onChange={e => setValues({ ...values, email: e.target.value.toLowerCase().trim() })}
/>
<FormControl sx={{ m: 1 }} variant="outlined">
  <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
  <OutlinedInput
    fullWidth
    id="outlined-adornment-password"
    type={showPassword ? 'text' : 'password'}
    endAdornment={
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          onMouseUp={handleMouseUpPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    }
    label="Пароль"
    onChange={e => setValues({ ...values, password: e.target.value })}
    onKeyDown={e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        // Здесь вызываем функцию для обработки входа
        handleLogin();
      }
    }}
    autoComplete="current-password" // Добавьте этот атрибут
  />
</FormControl>
               
                <Button                
                            variant="contained"
                            key="login"
                            onClick={handleLogin}
                        >Вход</Button>  
                    <Button                
                            variant="outlined"
                            key="cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>  
                        <Button                
                            onClick={handleRestore}
                        >Забыли пароль? </Button>            
                </Box>
         );
}
 
export default Login;