 import "./../styles/main.css"
import { useState,useEffect,useContext } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Login from "./Login";
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import Registration from "./Registration";
import { Helmet } from 'react-helmet';
import axios from 'axios';
import {Context} from "../context";
import logo from "./../img/Logo.png"


const Home = (props) => { 
const contextValue=useContext(Context)
const[itemData,setItemData] = useState([])   
const[loginWindow,loginWindowActive]=useState(false)
const[regWindow,regWindowActive]=useState(false)

  
  const [images, setImages] = useState(itemData);
  const maxImages=30; 
  const [interval_time, setIntervalTime] = useState(0);
  useEffect(()=>{
    setIntervalTime(0)},[contextValue.animatedOne])
  useEffect(() => {
    if (images.length>0)setIntervalTime(2000)
  if(contextValue.animatedOne)
    {  
      const interval = setInterval(() => {
      if (images.length < maxImages) {
      for(let i=0;i<5;i++){      
      const randomIndex = Math.floor(Math.random() * itemData.length);
      const newImage = {
  
        src: `./uploads/images/${itemData[randomIndex]}?w=248&h=496&fit=crop&auto=format`,
        id: Date.now() + Math.random(), // Уникальный ключ для каждого изображения
        left: Math.random() * 100 + '%', // Случайное положение по горизонтали
        bottom: Math.random() * 100 + '%',
      };
      setImages((prev) => [...prev, newImage]);}      
      }
      if (images.length >= maxImages-5) {
       setImages(images?.split(0,5));}
    }, interval_time); // Интервал появления изображений (1 секунда)

    return () => clearInterval(interval);}
  }, [interval_time,images, itemData, maxImages]);


  const handleLogOut=()=>
    {
      axios.get('/api/users/logout')
      .then(res=>
        { 
          contextValue.setAuth(false); 
          contextValue.setSuperUser(0);   
        })
        .catch(err=> console.log(err));
    }
  function DownloadImage()
    {            
        axios.get(`/api/auth/files/getImages`)
        .then(res=>{setItemData(res.data)})
        .catch(err=>console.log(err)) 
    } 
    useEffect(()=>{DownloadImage()},[])
    return ( 
      <>
       <Helmet>
        <title>Главная</title>
        <meta name="description" content="Система автоматизации проведения соревнований по спортивной акробатике" />
      </Helmet>
      <main class="section">
      <FormDialog title={"Авторизация"}  active={loginWindow} setActive={loginWindowActive} children={Login} />
      {regWindow&&<FormDialog title={"Регистрация"}  active={regWindow} setActive={regWindowActive} children={Registration}/>}
      <div style={{ zIndex: 1, position: 'relative', height: '100vh',
      overflow:'hidden' }}>
 {contextValue.animatedOne&& <Box
    sx={{
      flex: 1,
      backgroundImage: 'url(/path/to/your/background/image.jpg)', // Укажите путь к изображению фона
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    
    <div className="images_fall">
      {images.map((image) => (
        <img
          key={image.id}
          src={image.src}
          alt=""
          className="image"
          style={{ left: image.left, bottom:image.bottom }}
          onAnimationEnd={() => {
            // Удаляем изображение после завершения анимации
            setImages((prev) => prev.filter((img) => img.id !== image.id));
          }}
        />
      ))}
    </div>}
  </Box>}
  <Box
    sx={{
      position: 'absolute', // Устанавливаем абсолютное позиционирование
      top: '40%', // Центрируем по вертикали
      left: '50%', // Центрируем по горизонтали
      transform: 'translate(-50%, -60%)', // Сдвигаем элемент на половину его ширины и высоты
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2, // Убедитесь, что он поверх остальных элементов
    }}
  >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 3,
            padding: 4,
            maxWidth: 400
          }}
        >
         <Box sx={{ flexGrow: 1,alignItems: 'center', display: { xs: 'flex', md: 'flex' } }}>        
      <img src={logo} className="desktop-base-header"></img> 
      <Typography
            variant="h5"
            noWrap
            align="left"
            component="a"
            href="/"
            sx={{
              mt: 3,
              ml:-2,
              display: { md: 'flex' },
              textAlign: 'center',
              fontWeight: 'bold',
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',

            }}
          >
            CRO<p>PRO</p>
        </Typography>
        </Box>
        <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: 2 }}>
          Система автоматизации проведения соревнований по спортивной акробатике
        </Typography>
        {!contextValue.auth?<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1, width: '100%', maxWidth: 400 }}>
          <Button variant="contained" onClick={() => loginWindowActive(true)}>
            Вход
          </Button>
          
          <Button variant="contained" onClick={() => regWindowActive(true)}>
            Регистрация
          </Button>
          
        </Box>
        :
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 1, width: '100%', maxWidth: 400 }}>
          <Button variant="contained" onClick={handleLogOut}>
            Выход
          </Button>
        </Box>}
      </Box>      
    </Box>    
      
       </div>
      </main>
        </>
      );
}
 
export default Home;