import { useState } from "react";
import AddSostav from "../ModalWindow/AddSostav/AddSostav";
import SostavsTable from "../components/sostavsTable/SostavsTable";
import SostavsTableArchive from "../components/sostavsTable/SostavsTableArchive";
import React from "react";
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Helmet } from 'react-helmet';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { styled } from '@mui/material/styles';

const PrintButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(2)
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p:3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const Sostavs = (props) => {
  const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const[addSostavActive,setaddSostavActive]=useState(false)
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    return ( 
        <>
        <Helmet>
        <title>Составы</title>
        <meta name="description" content="Управляйте своими составами" />
        </Helmet>
        <PrintButton color="secondary" aria-label="add" onClick={handlePrint}>
                  <PrintOutlinedIcon />
                </PrintButton>    
        {addSostavActive&&<FormDialog title={"Новый состав"} active={addSostavActive} setActive={setaddSostavActive} children={AddSostav} setOpenAlert={props.setOpenAlert} setTypeMsg={props.setTypeMsg} setMsg={props.setMsg} />}
        
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                        <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>
                            <Button 
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />} 
                                  size="large"
                                  key="addChampionat"
                                  onClick={()=>setaddSostavActive(true)}
                                  fullWidth
                                  style={{display:'flex',maxHeight: '100px', minHeight: '50px',borderRadius:0,marginBottom:'10px'}}
                                >Новый состав</Button> 
                            <Tabs
                                value={value}
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"                               
                              >
                               
                                <Tab label="Составы" {...a11yProps(0)} />                                
                                <Tab label="Составы в архиве" {...a11yProps(1)} />
                              </Tabs>
                                <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                                >
                                
                                <TabPanel value={value} index={0} dir={theme.direction}>
                            <div  ref={componentRef}>
                              <SostavsTable setOpenAlert={props.setOpenAlert} setTypeMsg={props.setTypeMsg} setMsg={props.setMsg} />
                            </div>
                            
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>                                  
                                        <SostavsTableArchive setOpenAlert={props.setOpenAlert} setTypeMsg={props.setTypeMsg} setMsg={props.setMsg}/>
                                </TabPanel>
                            </SwipeableViews>
                           
                          </Box>
            </div>
        </div>
        </div>
        </>
     );
}
 
export default Sostavs;